<template>
  <Card class="trade-card" v-bind="$props">
    <div class="lg:pt-s16 lg:mx-s16 pb-s16 pt-s24 mx-s24 border-b border-border">
      <slot name="header" />
    </div>
    <div class="lg:pb-s16 lg:px-s16 pt-s16 px-s24 pb-s24">
      <slot />
    </div>
  </Card>
</template>
<script>
import { Card } from '@/components/misc';

export default {
  name: 'TradeCard',
  components: {
    Card,
  },
  props: {
    ...Card.props
  }
};
</script>
