<template>
  <Collapse
    class="bg-background-primary border border-border rounded-24"
    :customClasses="collapseClasses"
  >
    <div class="text-left text-n-4xl font-bold md:text-n-3xl" slot="title">{{ $t('trade.order_book') }}</div>

    <div class="flex justify-end relative" v-click-outside="closeDropdown">
      <Button
        :label="`$${dropdownSelected}`"
        icon="IconTriangle"
        :iconClasses="`default-transition w-3-5 h-3-5 ml-1-5 text-text-body ${isOpen ? 'rotate-180' : ''}`"
        :iconAfter="true"
        version="v3"
        customClasses="px-2 py-2px"
        @click.native="toggleDropdown"
      />
      <div class="dropdown-content" v-if="isOpen">
        <div
            v-for="(option) in dropdownOptions"
            :key="`dropdown_option_${option}`"
            :class="`px-2 py-4px hover:bg-background-primary hover:text-text-inactive ${dropdownSelected === option ? 'font-bold' : 'font-medium'}`"
            @click="dropdownSelect(option)"
        >
          ${{ option }}
        </div>
      </div>
    </div>

    <div class="w-full flex mt-s16 mb-s8 subheadline-small">
      <div class="w-1/3">{{ $t('trade.price_usd') }}</div>
      <div class="w-1/3 text-right">{{ $t('trade.amount') }}</div>
      <div class="w-1/3 text-right">{{ $t('trade.total_usd') }}</div>
    </div>

    <div
        v-for="(sell) in sells"
        :key="sell.id"
        class="w-full flex font-monospace text-n-lg mt-1 text-text-negative xs:text-sm12 font-bold"
    >
      <div class="w-1/3">{{ numberFormat(sell.price_rounded) }}</div>
      <div class="w-1/3 text-right">{{ numberFormat(sell.amount, 6) }}</div>
      <div class="w-1/3 text-right">{{ numberFormat(sell.total) }}</div>
    </div>

    <div class="w-full flex text-n-xl mb-3 mt-3 xs:text-n-lg font-bold">
      <div class="w-1/2 text-left">{{ $t('trade.last_price') }}</div>
      <div class="w-1/2 font-monospace text-right">${{ numberFormat(lastPrice) }}</div>
    </div>

    <div
        v-for="(buy) in buys"
        :key="buy.id"
        class="w-full flex font-monospace text-n-lg font-semibold text-text-positive xs:text-sm12"
    >
      <div class="w-1/3">{{ numberFormat(buy.price_rounded) }}</div>
      <div class="w-1/3 text-right">{{ numberFormat(buy.amount, 6) }}</div>
      <div class="w-1/3 text-right">{{ numberFormat(buy.total) }}</div>
    </div>
  </Collapse>
</template>

<script>
import Button from '@/components/misc/Button';
import Collapse from '@/components/misc/Collapse/Collapse';
import ORDERBOOK from '@/graphql/queries/Orderbook.gql';

export default {
  name: 'Orderbook',
  components: {
    Button,
    Collapse,
  },
  props: {
    orderbook: Object,
    recentTrades: Array,
    lastPrice: Number,
  },

  data() {
    const largeBuy = this.orderbook && this.orderbook.buys.length && this.orderbook.buys[0].price_rounded >= 10000;

    return {
      collapseClasses: {
        button: 'p-s24',
        content: 'px-s24',
        contentActive: 'pb-s24',
      },
      dropdownSelected: 10,
      dropdownOptions: largeBuy ? [1, 5, 10, 50, 100, 1000] : [1, 5, 10, 50, 100],
      isOpen: false,
      numRows: 5,
    };
  },

  computed: {
    sells() {
      const sells = [...this.orderbook.sells];
      const length = sells.length;

      return sells.reverse().splice(length - this.numRows, length).map(x => {
        const price_rounded = x.price_rounded;
        const amount = x.amount_base;
        return {
          ...x,
          price_rounded,
          amount,
          total: x.amount_quote,
          id: `${x.price_rounded}${x.amount_quote}`.hashCode(),
        };
      });
    },

    buys() {
      const buys = [...this.orderbook.buys];

      return buys.splice(0, this.numRows).map(x => {
        const price_rounded = x.price_rounded;
        const amount = x.amount_base;
        return {
          ...x,
          price_rounded,
          amount,
          total: x.amount_quote,
          id: `${x.price_rounded}${x.amount_quote}`.hashCode(),
        };
      });
    },
  },

  methods: {
    async dropdownSelect(option) {
      const response = await this.$apollo.query({
        query: ORDERBOOK,
        variables: {
          pair: this.$route.params.pair,
          orderbookDecimals: -option,
        },
        fetchPolicy: 'no-cache',
      });
      const updatedPair = { ...this.$store.state.api['pair'] };
      updatedPair.orderbook = response.data.pair.orderbook;

      this.$store.commit('api/setState', {
        stateName: 'pair',
        stateData: updatedPair,
      });

      this.dropdownSelected = option;
      this.isOpen = false;
    },

    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },

    closeDropdown() {
      this.isOpen = false;
    },
  },
};
</script>

<style scoped>
  .orderbook {
    @apply font-semibold;
  }

  .dropdown-content {
    @apply absolute mt-8 bg-background-primary border border-border rounded text-sm12 cursor-pointer z-10;
  }
</style>
