<template>
  <TradeWidgetV2
    @refreshPageData="loadData"
    class="trade-widget"
    :asset="asset"
    :initialMode="initialMode"
    hideTabSelector
  />
</template>

<script>
import { mapActions } from 'vuex';
import { TradeWidgetV2 } from '@/components/trade';

import TRADE from '@/graphql/queries/Trade.gql';

export default {
  name: 'Trade',
  components: {
    TradeWidgetV2,
  },

  props: {
    asset: {
      type: Object,
      required: true,
    },
    chartRange: {
      type: Object,
      required: true,
    },
    initialMode: {
      type: String,
      default: 'buy_card',
    },
  },

  methods: {
    ...mapActions('ui', ['setModalProps']),

    async handleClose() {
      this.hideModal();
    },

    async loadData() {
      this.setModalProps({
        customClose: this.handleClose,
      });

      await this.$store.dispatch('api/refreshPageData', {
        $apollo: this.$apollo,
        query: TRADE,
        variables: {
          pair: this.$route.params.pair,
          chartEndTimeSeconds: new Date().getTime() / 1000,
          orderbookDecimals: -10,
          window: this.chartRange.window,
          resolution: this.chartRange.resolution,
        },
      });
    },
  },
};
</script>

<style scoped>
  .trade-widget {
    @apply mt-s8;
    width: 400px;
    height: 570px;
  }

@screen xl {
  .trade-widget {
    width: unset;
    height: unset;
  }
}
</style>
