<template>
  <div class="underline-bar" :class="`size-${size}`">
  </div>
</template>

<script>
export default {
  name: 'UnderlineBar',
  props: {
    size: {
      type: String,
      default: '5',
    }
  },
};
</script>

<style scoped>
  .underline-bar {
    @apply bg-border-active-nav;
    width: 100%;
    border-radius: 20px;
  }
  .size-5 {
    height: 5px;
  }
  .size-4 {
    height: 4px;
  }
</style>
