<template>
  <section id="trade-widget" @confirmTrade="trade">
    <div>
      <div class="z-10 relative flex justify-center">
        <Carousel :isLoading="isSkeletonLoading" :values="images" />
      </div>

      <!-- Trading area -->
      <div v-if="isSkeletonLoading">
        <SkeletonLoading height="737px" class="trade-widget" />
      </div>
      <div v-else class="trade-widget rounded-16 md:rounded-b-none">
        <div v-if="!result">
          <div class="py-3 px-5">
            <div class="flex justify-between items-center">
              <div
                class="cursor-pointer font-bold default-transition"
                :class="
                  type === TRADE_TYPE.BUY
                    ? 'text-xl23'
                    : 'text-xl23 text-text-body'
                "
                @click="changeTradeType(TRADE_TYPE.BUY)"
              >
                {{ $t('trade.buy') }}
                <UnderlineBar v-if="type === TRADE_TYPE.BUY" />
              </div>

              <div
                class="cursor-pointer font-bold default-transition"
                :class="
                  type === TRADE_TYPE.SELL
                    ? 'text-xl23'
                    : 'text-xl23 text-text-body'
                "
                @click="changeTradeType(TRADE_TYPE.SELL)"
              >
                {{ $t('trade.sell') }}
                <UnderlineBar v-if="type === TRADE_TYPE.SELL" />
              </div>
            </div>
            <div
              class="
                flex
                justify-between
                items-center
                bg-background-positive-text bg-opacity-40
                mb-4
                text-sm14
                rounded-10
                mt-5
              "
            >
              <div
                class="
                  flex-1 flex
                  justify-center
                  items-center
                  rounded-10
                  py-2
                  cursor-pointer
                  font-bold
                  default-transition
                "
                :class="
                  mode === TRADE_MODE.INSTANT
                    ? 'text-text-positive bg-background-positive-text'
                    : 'opacity-40 hover:bg-background-positive-text hover:opacity-60'
                "
                @click="changeModeType(TRADE_MODE.INSTANT)"
              >
                {{
                  type === TRADE_TYPE.BUY
                    ? $t('trade.instant_buy')
                    : $t('trade.instant_sell')
                }}
              </div>

              <div
                class="
                  flex-1 flex
                  justify-center
                  items-center
                  rounded-10
                  py-2
                  cursor-pointer
                  font-bold
                  default-transition
                "
                :class="
                  mode === TRADE_MODE.LIMIT
                    ? 'text-text-positive bg-background-positive-text'
                    : 'opacity-40 hover:bg-background-positive-text hover:opacity-60'
                "
                @click="changeModeType(TRADE_MODE.LIMIT)"
              >
                {{
                  type === TRADE_TYPE.BUY
                    ? $t('trade.limit_buy')
                    : $t('trade.limit_sell')
                }}
              </div>
            </div>

            <div
              v-if="mode === TRADE_MODE.LIMIT && firstStep"
              class="flex flex-col mt-2"
            >
              <div class="flex">
                <div
                  class="
                    w-1/2
                    flex
                    items-center
                    text-md
                    font-semibold
                    xs:text-sm15
                  "
                >
                  <div>
                    {{
                      type === TRADE_TYPE.BUY
                        ? $t('trade.limit_buy_price')
                        : $t('trade.limit_sell_price')
                    }}
                  </div>
                </div>

                <DollarInput
                  class="w-1/2"
                  customClasses="v5 h-11-5"
                  @input="setPriceAmount"
                  :initialValue="price"
                />
              </div>

              <div
                class="
                  flex
                  justify-start
                  items-center
                  h-8
                  text-sm12
                  font-semibold
                "
              >
                <div class="w-1/2">{{ $t('trade.last_card_price') }}:</div>
                <div class="pl-3">
                  ${{ numberFormat(data.price_per_token) }}
                </div>
              </div>

              <div class="font-medium mt-7-5">
                <div v-if="type === TRADE_TYPE.BUY">
                  <div class="text-xl font-bold">
                    {{ $t('trade.what_is_limit_buy') }}
                  </div>
                  <div class="mt-3">{{ $t('trade.limit_buys_will_not') }}</div>
                  <div class="mt-3">{{ $t('trade.your_buy_order') }}</div>
                  <div class="mt-3">
                    {{ $t('trade.unfilled_and_partially') }}
                  </div>
                </div>
                <div v-else>
                  <div class="text-xl font-bold">
                    {{ $t('trade.what_is_limit_sell') }}
                  </div>
                  <div class="mt-3">{{ $t('trade.limit_sells_will_not') }}</div>
                  <div class="mt-3">{{ $t('trade.your_sell_order') }}</div>
                  <div class="mt-3">
                    {{ $t('trade.unfilled_and_partially') }}
                  </div>
                </div>

                <ButtonV2
                  class="mt-n-4xl"
                  :inactive="price <= 0"
                  :label="$t('trade.set_limit_price')"
                  testId="btn-limitPrice"
                  :version="type === TRADE_TYPE.BUY ? 'primary' : 'destructive'"
                  @onClick="setLimitPrice"
                  wide
                />
              </div>
            </div>
            <div v-else>
              <div v-if="mode === TRADE_MODE.LIMIT" class="flex">
                <div
                  class="
                    w-1/2
                    flex
                    items-center
                    text-md
                    font-semibold
                    xs:text-sm15
                  "
                >
                  <div>
                    {{
                      type === TRADE_TYPE.BUY
                        ? $t('trade.limit_buy_price')
                        : $t('trade.limit_sell_price')
                    }}
                  </div>
                </div>

                <DollarInput
                  class="w-1/2"
                  customClasses="v5 h-11-5"
                  @input="setPriceAmount"
                  :initialValue="price"
                />
              </div>

              <div
                class="
                  flex
                  justify-start
                  items-center
                  h-8
                  text-sm12
                  font-semibold
                "
              >
                <div class="w-1/2">{{ $t('trade.last_card_price') }}:</div>
                <div class="pl-3">
                  ${{ numberFormat(data.price_per_token) }}
                </div>
              </div>

              <div class="flex justify-between items-center mt-4 h-12">
                <div class="w-1/2 flex items-center text-sm15 font-semibold">
                  {{
                    type === TRADE_TYPE.BUY
                      ? $t('trade.amount_to_buy')
                      : $t('trade.amount_to_sell')
                  }}
                </div>

                <DollarInput
                  v-if="type === TRADE_TYPE.BUY"
                  class="w-1/2"
                  customClasses="v5 pr-10-2 h-11-5"
                  @input="setSendAmount"
                  :initialValue="sendAmount"
                  :maxValue="userBalance"
                />
                <div v-else class="w-1/2 relative">
                  <input
                    v-on:input="debouncer()"
                    placeholder="0"
                    v-model="sendAmount"
                    data-testid="input-send-amount"
                    class="v5 pr-10-2 h-11-5 no-arrows"
                    type="text"
                    data-lpignore="true"
                  />
                  <div
                    @click="setMaxAmount"
                    class="
                      absolute
                      top-0
                      right-0
                      mt-4-5
                      mr-3
                      text-sm10 text-text-positive
                      font-semibold
                      cursor-pointer
                    "
                  >
                    {{ $t('trade.max') }}
                  </div>
                </div>
              </div>

              <div
                class="
                  flex
                  justify-start
                  items-center
                  h-8
                  text-sm12
                  font-semibold
                "
              >
                <div class="w-1/2">
                  {{
                    $t(
                      type === TRADE_TYPE.BUY
                        ? 'current_balance.buying_power'
                        : 'trade.ownership',
                    )
                  }}:
                </div>
                <div class="pl-3">
                  {{
                    type === TRADE_TYPE.BUY
                      ? `$${numberFormat(sendingBalance)}`
                      : numberFormat(sendingBalance, 6)
                  }}
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="mode !== TRADE_MODE.LIMIT || !firstStep"
            class="trade-widget-result"
          >
            <div class="flex">
              <div class="w-1/2 flex items-center text-xl26 font-bold">
                {{ $t('trade.you_get') }}
              </div>
              <div class="w-1/2">
                <input
                  data-testid="input-trade-buy"
                  v-model="formattedReceiveAmount"
                  :placeholder="`${type === TRADE_TYPE.BUY ? '~0' : '$0'}`"
                  class="v5 pr-3 h-11-5 cursor-not-allowed"
                  type="text"
                  data-lpignore="true"
                  disabled
                />
              </div>
            </div>

            <div
              class="
                flex
                justify-between
                items-center
                mt-2-4
                h-8
                text-sm15
                font-semibold
              "
            >
              <div>
                {{
                  $t(
                    type === TRADE_TYPE.BUY
                      ? 'current_balance.card_balance'
                      : 'current_balance.usd_balance',
                  )
                }}:
              </div>
              <div>
                {{
                  type === TRADE_TYPE.BUY
                    ? numberFormat(receivingBalance, 6)
                    : `$${numberFormat(receivingBalance)}`
                }}
              </div>
            </div>

            <div
              v-if="averagePrice"
              class="
                flex
                justify-between
                items-center
                mt-2-4
                h-8
                text-sm15
                font-semibold
              "
            >
              <div>
                {{
                  $t(
                    type === TRADE_TYPE.BUY
                      ? 'trade.average_buy_price'
                      : 'trade.average_sell_price',
                  )
                }}:
              </div>
              <div class="text-text-positive">
                ${{ numberFormat(averagePrice) }}
              </div>
            </div>

            <div
              v-if="tradeFee"
              class="
                flex
                justify-between
                items-center
                mt-2-4
                h-8
                text-sm15
                font-semibold
              "
            >
              <div>{{ $t('trade.trade_fee') }}:</div>
              <div>${{ numberFormat(tradeFee) }}</div>
            </div>

            <div class="mt-auto">
              <ButtonV2
                v-if="mode !== TRADE_MODE.LIMIT"
                :inactive="disableButton || isLoading"
                :label="
                  $t(
                    !isKycComplete
                      ? 'kyc.verify_identity'
                      : type === TRADE_TYPE.SELL
                      ? 'trade.sell'
                      : isUserBalanceEnough
                      ? 'trade.buy'
                      : 'wallet.add_funds',
                  )
                "
                :version="type === TRADE_TYPE.SELL ? 'destructive' : 'primary'"
                :wide="true"
                testId="btn-funds1"
                @onClick="confirmTrade"
              />
              <ButtonV2
                v-else
                :isDisabled="disableButton || isLoading"
                :label="
                  $t(
                    !isKycComplete
                      ? 'kyc.verify_identity'
                      : !isUserBalanceEnough && type === TRADE_TYPE.BUY
                      ? 'wallet.add_funds'
                      : 'trade.place_order',
                  )
                "
                :version="type === TRADE_TYPE.SELL ? 'destructive' : 'primary'"
                :wide="true"
                testId="btn-funds2"
                @onClick="confirmTrade"
              />
            </div>
          </div>
        </div>

        <TradeWidgetResult
          v-if="result"
          :result="result"
          :order="order"
          :tradeType="type"
          :tradeMode="mode"
          :asset="data.base.name"
          :on-callback="reset"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'vue-debounce';

import { Carousel } from '@/components/misc';
import UnderlineBar from '@/components/misc/UnderlineBar';
import ButtonV2 from '@/stories/misc/ButtonV2';
import TradeWidgetResult from '@/components/trade/TradeWidgetResult';
import DollarInput from '@/components/misc/DollarInput';

import BUY_ESTIMATE from '@/graphql/queries/BuyEstimate.gql';
import SELL_ESTIMATE from '@/graphql/queries/SellEstimate.gql';

import BUY from '@/graphql/mutations/Buy.gql';
import SELL from '@/graphql/mutations/Sell.gql';

const TRADE_MODE = {
  INSTANT: 'INSTANT',
  LIMIT: 'LIMIT',
};

const TRADE_TYPE = {
  BUY: 'BUY',
  SELL: 'SELL',
};

export default {
  name: 'TradeWidget',
  components: {
    Carousel,
    UnderlineBar,
    ButtonV2,
    TradeWidgetResult,
    DollarInput,
  },

  data() {
    return {
      isLoading: false,
      TRADE_MODE,
      TRADE_TYPE,
      mode: TRADE_MODE.INSTANT,
      firstStep: true,
      type:
        this.$route.params.action && this.$route.params.action === 'sell'
          ? TRADE_TYPE.SELL
          : TRADE_TYPE.BUY,
      user: this.$store.state.api['current_user'],
      isUserBalanceEnough: true,
      sendAmount: '',
      receiveAmount: '',
      formattedReceiveAmount: '',
      averagePrice: '',
      tradeFee: '',
      price: '',
      result: null,
      order: null,
    };
  },

  mounted() {
    this.debouncer = debounce(async () => {
      await this.getEstimate();
    }, 300);
    this.$root.$on('confirm-trade', () => {
      this.trade();
    });
  },

  beforeDestroy() {
    this.$root.$off('confirm-trade');
  },

  methods: {
    ...mapActions('user', ['requiresKycComplete', 'updateKycComplete']),

    setMaxAmount() {
      this.sendAmount = this.sendingBalance;
      this.getEstimate();
    },

    setSendAmount(value) {
      this.sendAmount = value;
      this.debouncer();
    },

    setPriceAmount(value) {
      this.price = value;
      this.debouncer();
    },

    async getEstimate() {
      try {
        this.isLoading = true;

        if (
          isNaN(this.sendAmount) ||
          !parseFloat(this.sendAmount) ||
          !parseFloat(this.getLimit())
        ) {
          this.isUserBalanceEnough = true;
          this.formattedReceiveAmount = '';
          this.averagePrice = '';
          this.tradeFee = '';
          return;
        }

        this.isUserBalanceEnough = this.$big(this.sendAmount || '0').lte(
          this.userBalance,
        );
        await this.updateKycComplete();

        switch (this.type) {
          case TRADE_TYPE.BUY:
            await this.buyEstimate();
            break;
          case TRADE_TYPE.SELL:
            await this.sellEstimate();
            break;
        }
      } catch (err) {
        await this.showError(err);
      } finally {
        this.isLoading = false;
      }
    },

    async buyEstimate() {
      const response = await this.$apollo.query({
        query: BUY_ESTIMATE,
        variables: {
          pair: this.$route.params.pair,
          amount: this.$big(this.sendAmount),
          limit: this.getLimit().toString(),
        },
        fetchPolicy: 'no-cache',
      });

      const buyEstimate = response.data.buy_estimate;

      this.receiveAmount = buyEstimate.asset_out;
      this.formattedReceiveAmount = `~${this.numberFormat(
        buyEstimate.received,
        6,
      )}`;
      this.averagePrice = buyEstimate.average_price;
      this.tradeFee = this.$big(buyEstimate.average_price).times(
        buyEstimate.fee,
      );
    },

    async sellEstimate() {
      const response = await this.$apollo.query({
        query: SELL_ESTIMATE,
        variables: {
          pair: this.$route.params.pair,
          amount: this.$big(this.sendAmount),
          limit: this.getLimit().toString(),
        },
        fetchPolicy: 'no-cache',
      });

      const sellEstimate = response.data.sell_estimate;

      this.receiveAmount = sellEstimate.asset_out;
      this.formattedReceiveAmount = `$${this.numberFormat(
        sellEstimate.received,
      )}`;
      this.averagePrice = sellEstimate.average_price;
      this.tradeFee = sellEstimate.fee;
    },

    reset() {
      if (this.result === 'success' && this.isMobileDevice) {
        return this.$router.push('').catch(() => {});
      }
      this.result = null;
    },

    async changeTradeType(type) {
      if (this.type === type) {
        return;
      }

      this.$router.push({
        name: 'trade-action',
        params: {
          action: type === TRADE_TYPE.BUY ? 'buy' : 'sell',
        },
      });

      this.formattedReceiveAmount = '';
      this.sendAmount = '';
      this.averagePrice = '';
      this.tradeFee = '';

      this.type = type;
      this.firstStep = true;

      await this.emitOrderTypeChanged();
    },

    async changeModeType(mode) {
      if (this.mode === mode) {
        return;
      }

      this.formattedReceiveAmount = '';
      this.averagePrice = '';
      this.tradeFee = '';
      this.mode = mode;
      this.firstStep = true;

      if (mode === TRADE_MODE.INSTANT) {
        this.debouncer();
      }

      await this.emitOrderTypeChanged();
    },

    async emitOrderTypeChanged() {
      const assetSpecs = this.data.base.specs.reduce(
        (accum, spec) => ({
          ...accum,
          [`asset_${spec.key}`]: spec.value,
        }),
        {},
      );

      await this.$store.dispatch('events/track', {
        event: 'ASSET_CHECKOUT_ORDER_TYPE_CHANGED',
        variables: {
          id: this.data.id,
          id_hr: this.data.id_hr,
          base_name: this.data.base.name,
          base_ticker: this.data.base.ticker,
          quote_name: this.data.quote.name,
          quote_ticker: this.data.quote.ticker,
          price: this.data.price_per_token,
          base_supply: this.data.base.supply,
          price_change_24h: this.data.price_change_24h,
          volume_base_change_24h: this.data.volume_base_24h,
          volume_quote_change_24h: this.data.volume_quote_24h,
          market_cap: this.data.market_cap,
          transaction_type: this.type,
          order_type: this.mode,
          ...assetSpecs,
          player_id: this.data.base.associated_players[0].id,
          player_id_hr: this.data.base.associated_players[0].id_hr,
        },
      });
    },

    getLimit() {
      if (this.mode === TRADE_MODE.INSTANT) {
        if (this.type === TRADE_TYPE.BUY) {
          return 1000000000;
        } else {
          return 0.000001;
        }
      } else if (this.mode === TRADE_MODE.LIMIT) {
        return this.price;
      }
    },

    async confirmTrade() {
      try {
        let { send, receive, limit, message } = '';

        send =
          this.type === this.TRADE_TYPE.BUY
            ? this.sendAmount
            : this.numberFormat(this.sendAmount, 6);

        if (this.mode === 'INSTANT') {
          receive =
            this.type === this.TRADE_TYPE.BUY
              ? this.numberFormat(this.receiveAmount, 6)
              : this.formattedReceiveAmount;
          message =
            this.type === this.TRADE_TYPE.BUY
              ? 'trade.confirm_buy_instant'
              : 'trade.confirm_sell_instant';
        } else if (this.mode === 'LIMIT') {
          receive = this.formattedReceiveAmount;
          message =
            this.type === this.TRADE_TYPE.BUY
              ? 'trade.confirm_buy_limit'
              : 'trade.confirm_sell_limit';
          limit = this.price;
        }

        await this.requiresKycComplete();

        if (!this.isUserBalanceEnough && this.type === TRADE_TYPE.BUY) {
          return this.showModal('PaymentInterface', { isDeposit: true });
        }

        this.$store.commit('ui/showPrompt', {
          eventName: 'confirm-trade',
          title: this.$t('trade.confirm_transaction'),
          message: this.$t(message, { receive, send, limit }),
        });
      } catch (err) {
        await this.$store.dispatch('ui/showError', err);
      }
    },

    async trade() {
      this.isLoading = true;
      try {
        const assetSpecs = this.data.base.specs.reduce(
          (accum, spec) => ({
            ...accum,
            [`asset_${spec.key}`]: spec.value,
          }),
          {},
        );

        await this.$store.dispatch('events/track', {
          event: 'ASSET_CHECKOUT_SUBMITTED',
          variables: {
            id: this.data.id,
            id_hr: this.data.id_hr,
            base_name: this.data.base.name,
            base_ticker: this.data.base.ticker,
            quote_name: this.data.quote.name,
            quote_ticker: this.data.quote.ticker,
            price: this.data.price_per_token,
            base_supply: this.data.base.supply,
            price_change_24h: this.data.price_change_24h,
            volume_base_change_24h: this.data.volume_base_24h,
            volume_quote_change_24h: this.data.volume_quote_24h,
            market_cap: this.data.market_cap,
            base_amount:
              this.type === TRADE_TYPE.SELL ? this.$big(this.sendAmount) : null,
            quote_amount: this.type === TRADE_TYPE.BUY ? this.sendAmount : null,
            base_remaining_balance:
              this.type === TRADE_TYPE.SELL
                ? this.$big(this.sendingBalance).minus(this.sendAmount)
                : null,
            quote_remaining_balance:
              this.type === TRADE_TYPE.BUY
                ? this.$big(this.sendingBalance).minus(this.sendAmount)
                : null,
            transaction_type: this.type,
            order_type: this.mode,
            limit_price: this.mode === TRADE_MODE.LIMIT ? this.price : null,
            ...assetSpecs,
            player_id: this.data.base.associated_players[0].id,
            player_id_hr: this.data.base.associated_players[0].id_hr,
          },
        });

        const response = await this.$apollo.mutate({
          mutation: this.type === TRADE_TYPE.SELL ? SELL : BUY,
          variables: {
            pair: this.$route.params.pair,
            amount: this.$big(this.sendAmount),
            limit: this.getLimit().toString(),
            expected_asset_out: this.receiveAmount,
          },
        });
        this.$emit('trade');
        this.order =
          this.type === TRADE_TYPE.SELL
            ? {
                tradeFee: this.tradeFee,
                formattedReceiveAmount: this.formattedReceiveAmount,
                ...response.data.sell,
              }
            : {
                tradeFee: this.tradeFee,
                formattedReceiveAmount: this.formattedReceiveAmount,
                ...response.data.buy,
              };
        this.result = 'success';

        await this.$store.dispatch('events/track', {
          event: 'ASSET_CHECKOUT_RECEIPT_VIEWED',
          variables: {
            id: this.data.id,
            id_hr: this.data.id_hr,
            base_name: this.data.base.name,
            base_ticker: this.data.base.ticker,
            quote_name: this.data.quote.name,
            quote_ticker: this.data.quote.ticker,
            price: this.data.price_per_token,
            base_supply: this.data.base.supply,
            price_change_24h: this.data.price_change_24h,
            volume_base_change_24h: this.data.volume_base_24h,
            volume_quote_change_24h: this.data.volume_quote_24h,
            market_cap: this.data.market_cap,
            base_amount:
              this.type === TRADE_TYPE.SELL ? this.$big(this.sendAmount) : null,
            quote_amount: this.type === TRADE_TYPE.BUY ? this.sendAmount : null,
            base_remaining_balance:
              this.type === TRADE_TYPE.SELL
                ? this.$big(this.sendingBalance).minus(this.sendAmount)
                : null,
            quote_remaining_balance:
              this.type === TRADE_TYPE.BUY
                ? this.$big(this.sendingBalance).minus(this.sendAmount)
                : null,
            transaction_type: this.type,
            order_type: this.mode,
            limit_price: this.mode === TRADE_MODE.LIMIT ? this.price : null,
            ...assetSpecs,
            player_id: this.data.base.associated_players[0].id,
            player_id_hr: this.data.base.associated_players[0].id_hr,
          },
        });

        this.sendAmount = '';
        this.receiveAmount = '';
        this.formattedReceiveAmount = '';
        this.averagePrice = '';
        this.tradeFee = '';
      } catch (err) {
        await this.showError(err);
        if (
          err.graphQLErrors &&
          err.graphQLErrors.length &&
          err.graphQLErrors[0].message === 'price_moved_against_user'
        ) {
          return (await this.type) === TRADE_TYPE.BUY
            ? this.buyEstimate()
            : this.sellEstimate();
        }

        this.result = 'error';
      } finally {
        this.isLoading = false;
      }
    },

    setLimitPrice() {
      this.formattedReceiveAmount = '';
      this.averagePrice = '';
      this.tradeFee = '';
      this.firstStep = false;
    },
  },

  computed: {
    ...mapGetters('user', ['isKycComplete']),

    assetAmount() {
      return this.lodashGet(this.data, 'base.current_user_amount', 0);
    },
    sendingBalance() {
      return this.type === TRADE_TYPE.BUY ? this.userBalance : this.assetAmount;
    },

    receivingBalance() {
      return this.type === TRADE_TYPE.SELL
        ? this.userBalance
        : this.assetAmount;
    },

    payout() {
      if (this.mode === TRADE_MODE.INSTANT) {
        //todo: iterate orderbook
        return 0;
      } else {
        if (this.type === TRADE_TYPE.BUY) {
          if (this.price === 0) {
            return 0;
          }
          return this.$big(this.sendAmount).div(this.price);
        } else {
          return this.$big(this.sendAmount).times(this.price);
        }
      }
    },

    disableButton() {
      let default_criteria =
        isNaN(this.sendAmount) ||
        !parseFloat(this.sendAmount) ||
        !this.formattedReceiveAmount;
      if (this.type === 'SELL') {
        return (
          !parseFloat(this.assetAmount) ||
          this.sendAmount > 1 ||
          this.sendAmount > this.assetAmount ||
          default_criteria
        );
      } else {
        return default_criteria;
      }
    },
  },

  props: {
    data: Object,
    images: Array,
    userBalance: String,
    isSkeletonLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.trade-widget-image {
  object-fit: contain;
  width: 206px;
  height: 345px;
  border-radius: 5px;
  z-index: 1;
}

.trade-widget {
  @apply bg-background-primary px-2 pb-5 leading-tight border border-border;
  padding-top: 85px;
  margin-top: -75px;
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.trade-widget-result {
  @apply flex flex-col border border-border bg-background-primary py-4 px-5 mt-6;
  height: 300px;
  border-radius: 15px;
  position: relative;
}
</style>
