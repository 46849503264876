<template>
  <div class="bg-background-primary">
    <div v-if="result === 'success'" class="px-2 pt-4">
      <div v-if="tradeMode === TRADE_MODE.LIMIT" class="text-center font-bold text-xl24">
        <span v-if="tradeType === TRADE_TYPE.BUY" class="text-text-positive">
          {{$t('trade.limit_buy')}}
        </span>
        <span v-else class="text-text-negative">
          {{$t('trade.limit_sell')}}
        </span>
        {{$t('trade.confirmed')}}
      </div>
      <div v-else class="text-center font-bold text-xl24">
        {{$t(tradeType === TRADE_TYPE.BUY ? 'trade.purchase_confirmed' : 'trade.sale_confirmed')}}
      </div>

      <div class="flex justify-center mt-6">
        <span class="bg-background-positive-text p-10 rounded-full">
          <IconCheck class="w-12 h-12"/>
        </span>
      </div>

      <div class="success-receipt">
        <div v-if="tradeMode === TRADE_MODE.INSTANT">
          <div v-if="tradeType === TRADE_TYPE.BUY">
            <div class="flex justify-between mt-14 py-1">
              <div>{{$t('trade.you_paid')}}</div>
              <div>${{numberFormat(order.amount_executed)}}</div>
            </div>

            <div class="flex justify-between py-1">
              <div>{{$t('trade.trade_fee')}}</div>
              <div>${{numberFormat(instantBuyFee)}}</div>
            </div>

            <div class="flex justify-between py-1">
              <div>{{$t('trade.you_received')}}</div>
              <div>{{numberFormat(order.received, 6)}}</div>
            </div>
          </div>

          <div v-else>
            <div class="flex justify-between mt-14 py-1">
              <div>{{$t('trade.you_sold')}}</div>
              <div>{{numberFormat(order.amount_executed, 6)}}</div>
            </div>

            <div class="flex justify-between py-1">
              <div>{{$t('trade.trade_fee')}}</div>
              <div>${{numberFormat(order.fee)}}</div>
            </div>

            <div class="flex justify-between py-1">
              <div>{{$t('trade.you_received')}}</div>
              <div>${{numberFormat(order.received)}}</div>
            </div>
          </div>
        </div>

        <div v-else>
          <div v-if="tradeType === TRADE_TYPE.BUY">
            <div class="flex justify-between mt-14 py-1">
              <div>{{$t('trade.order_type')}}</div>
              <div class="text-text-positive">{{$t('trade.limit_buy')}}</div>
            </div>

            <div class="flex justify-between py-1">
              <div>{{$t('trade.usd_amount')}}</div>
              <div>${{numberFormat(orderAmount)}}</div>
            </div>

            <div class="flex justify-between py-1">
              <div>{{$t('trade.asset_price')}}</div>
              <div>${{numberFormat(order.average_price)}}</div>
            </div>

            <div class="flex justify-between py-1">
              <div>{{$t('trade.trade_fee')}}</div>
              <div>${{numberFormat(order.tradeFee)}}</div>
            </div>

            <div class="flex justify-between py-1">
              <div>{{$t('trade.estimated_card_amount')}}</div>
              <div>{{order.formattedReceiveAmount}}</div>
            </div>
          </div>

          <div v-else>
            <div class="flex justify-between mt-14 py-1">
              <div>{{$t('trade.order_type')}}</div>
              <div class="text-text-negative">{{$t('trade.limit_sell')}}</div>
            </div>

            <div class="flex justify-between py-1">
              <div>{{$t('trade.card_amount')}}</div>
              <div>{{numberFormat(orderAmount, 6)}}</div>
            </div>

            <div class="flex justify-between py-1">
              <div>{{$t('trade.asset_price')}}</div>
              <div>${{numberFormat(order.average_price)}}</div>
            </div>

            <div class="flex justify-between py-1">
              <div>{{$t('trade.trade_fee')}}</div>
              <div>${{numberFormat(order.tradeFee)}}</div>
            </div>

            <div class="flex justify-between py-1">
              <div>{{$t('trade.estimated_usd_amount')}}</div>
              <div>{{order.formattedReceiveAmount}}</div>
            </div>
          </div>

          <ButtonV2
            @onClick="$router.push('/orders/active')"
            :label="$t('trade.see_orders')"
            testId="btn-see-orders"
            class="mt-6"
            version="secondary"
            wide
          />
        </div>

        <ButtonV2
          @onClick="onCallback && onCallback()"
          :label="$t('done')"
          testId="btn-done"
          class="mt-6"
          wide
        />
      </div>
    </div>

    <div v-else-if="result === 'error'" class="px-5 py-4">
      <div class="flex justify-center">
        <span class="bg-background-negative-text p-10 rounded-full">
          <IconClose class="w-12 h-12 text-text-negative fill-current"/>
        </span>
      </div>

      <div class="text-center pt-6 px-4 mb-6 text-xl24 font-bold">
        {{ $t(tradeType === TRADE_TYPE.BUY ? 'trade.purchase_failed' : 'trade.sale_failed', {asset}) }}
      </div>

      <ButtonV2
        @onClick="onCallback && onCallback()"
        :label="$t('navigation.retry')"
        testId="btn-retry"
        version="destructive"
        wide
      />
    </div>
  </div>
</template>

<script>
import ButtonV2 from '@/stories/misc/ButtonV2';
import IconCheck from '@/assets/icons/check.svg';
import IconClose from '@/assets/icons/close.svg';

const TRADE_MODE = {
  'INSTANT': 'INSTANT',
  'LIMIT': 'LIMIT'
};

const TRADE_TYPE = {
  'BUY': 'BUY',
  'SELL': 'SELL'
};

export default {
  name: 'TradeWidgetResult',
  components: {
    ButtonV2,
    IconCheck,
    IconClose,
  },

  data() {
    return {
      TRADE_MODE,
      TRADE_TYPE,
    };
  },

  props: {
    asset: String,
    onCallback: Function,
    result: String,
    order: Object,
    tradeMode: String,
    tradeType: String,
  },

  computed: {
    orderAmount() {
      return this.$big(this.order.amount_remaining).plus(this.order.amount_executed);
    },
    instantBuyFee() {
      return this.$big(this.order.average_price).times(this.order.fee);
    },
  }
};
</script>

<style scoped>
  .success-receipt {
    @apply w-full font-semibold text-sm14 border border-border bg-background-primary rounded-16 p-5;
    margin-top: -62px;
  }
</style>