<template>
  <section class="trade-widget" v-show="!globalIsLoading" transition="fade-in">
    <!-- LOADING -->
    <div v-if="isTradeLoading" class="absolute top-0 bottom-0 right-0 left-0 bg-background-inactive bg-opacity-20 z-50">
      <Loading />
    </div>

    <!-- ASSET IMAGE -->
    <template v-if="!isMobileDevice && images">
      <div class="carousel-container">
        <Carousel imageType="trade_widget" :isLoading="loading" :values="images" />
      </div>
    </template>

    <h1
      class="mb-s32"
      :class="`${is_mobile.tablet ? 'headline-medium' : 'subheadline-medium'}`"
      v-if="isMobileDevice && asset_name"
    >
      {{ asset_name }}
    </h1>
    <!-- MODE/TYPE menu -->
    <template v-if="!show_review_order && !show_trade_confirmation">
      <div class="flex flex-col align-center justify-center">
        <template>
          <TabSelector
            v-if="!hideTabSelector"
            class="mb-s12"
            :tabsList="modes_tabs"
            :selectedTab="selected_mode"
            size="large"
            @tabAction="changeModesTab"
          />
          <SecondaryTabs
            :tabList="types_tabs.map((item) => $t(`navigation.${item}`))"
            :currentTab="selected_type"
            @tabChange="changeTypesTab"
          />
        </template>

        <!-- Amount Presets / Custom Amount -->
        <template v-if="show_preset_amount">
          <ToggleList
            class="mt-s12 mb-s24"
            :headers="preset_headers"
            :items="preset_items"
            :initialSelect="selected_preset_item"
            @itemSelectedIndex="selectPreset"
            @itemDeselected="deselectPreset"
          />

          <ButtonV2
            v-if="!selected_preset_item"
            :label="
              is_mode_buy ? $t('trade.custom_amount_purchase_btn_label') : $t('trade.custom_amount_sale_btn_label')
            "
            testId="btn-custom-amount-purchase"
            version="secondary"
            size="medium"
            :wide="true"
            @onClick="toggleCustomAmount"
          />

          <ButtonV2
            v-else
            version="primary"
            testId="btn-review_order"
            size="medium"
            :label="$t('trade.review_order')"
            :wide="true"
            @onClick="validateReviewOrder"
          />
        </template>
        <template v-else>
          <!-- BUY/SELL: INSTANT -->
          <div class="mt-s12 pt-s12" v-if="trade_type === 'INSTANT'">
            <CustomInput
              v-model="custom_amount_input"
              v-debounce:300="updateCustomAmount"
              :placeholder="is_mode_buy ? '$0' : '0%'"
              :filter="is_mode_buy ? 'money' : 'percent'"
              :testId="`${this.current_mode}-custom-amount`"
              :masked="false"
            >
              <div v-if="is_mode_buy">{{ $t('trade.how_much_spend') }}</div>
              <div v-if="!is_mode_buy">{{ $t('trade.how_much_sell') }}</div>
            </CustomInput>
            <SecondaryTabs
              class="grid mt-s12 mb-s24"
              :class="`grid-cols-${custom_amount_values.length}`"
              :tabList="custom_amount_values"
              :currentTab="selected_custom_amount"
              @tabChange="updateCustomAmount"
            />

            <ButtonV2
              class="mb-s12"
              :label="$t('trade.continue')"
              testId="btn-continue"
              version="primary"
              :inactive="!can_review_order"
              size="medium"
              :wide="true"
              @onClick="updatePresets"
            />

            <ButtonV2
              :label="$t('trade.view_purchase_presets')"
              version="secondary"
              testId="btn-view_purchase_presets"
              size="medium"
              :wide="true"
              @onClick="toggleCustomAmount"
            />
          </div>

          <!-- BUY/SELL: LIMIT -->
          <div class="mt-s12 pt-s12" v-if="trade_type === 'LIMIT'">
            <!-- if: BUY -->
            <CustomInput
              v-if="is_mode_buy"
              class="mb-s24"
              v-model="asset_price"
              v-debounce:300="calculateLimit"
              filter="money"
              testId="asset-price-input"
              placeholder="$0"
              v-on="isMobileDevice ? { blur: calculateLimit } : {}"
            >
              <div>{{ $t('trade.set_card_price') }}</div>
              <div slot="right-label" class="body-text-x-large">
                {{ $t('trade.current_price') }} <span class="text-text-positive">{{ formated_current_price }}</span>
              </div>
            </CustomInput>

            <CustomInput
              v-if="is_mode_buy"
              v-model="limit_buy_input"
              v-debounce:300="calculateLimit"
              filter="money"
              placeholder="$0"
              testId="limit-buy-amount"
              v-on="isMobileDevice ? { blur: calculateLimit } : {}"
            >
              <div>{{ $t('trade.purchase_amount') }}</div>
              <div slot="right-label">
                <div @click="openTooltip('limit_order')" class="underline cursor-pointer">
                  {{ $t('trade.whats_limit_order') }}
                </div>
              </div>
            </CustomInput>

            <!-- if: SELL -->
            <CustomInput
              v-else
              v-model="limit_sell_input"
              v-debounce:300="calculateLimit"
              filter="percent"
              testId="limit-sell-input"
              placeholder="0%"
              v-on="isMobileDevice ? { blur: calculateLimit } : {}"
            >
              <div>{{ $t('trade.card_qtd_sell') }}</div>
              <div slot="right-label">
                <div @click="openTooltip('limit_order')" class="underline cursor-pointer">
                  {{ $t('trade.whats_limit_order') }}
                </div>
              </div>
            </CustomInput>

            <SecondaryTabs
              class="grid mt-s12 mb-s24"
              :class="`grid-cols-${custom_amount_values.length}`"
              :tabList="custom_amount_values"
              :currentTab="is_mode_buy ? selected_limit_buy : selected_limit_sell"
              @tabChange="updateLimitAmount"
            />

            <CustomInput
              v-if="!is_mode_buy"
              class="mb-s24"
              v-model="asset_price"
              v-debounce:300="calculateLimit"
              filter="money"
              testId="asset-price-input"
              placeholder="$0"
              v-on="isMobileDevice ? { blur: calculateLimit } : {}"
            >
              <div>{{ $t('trade.set_card_price') }}</div>
              <div slot="right-label" class="body-text-x-large">
                {{ $t('trade.current_price') }} <span class="text-text-positive">{{ formated_current_price }}</span>
              </div>
            </CustomInput>

            <CustomInput
              v-if="is_mode_buy"
              class="mb-s24"
              v-model="card_quantity"
              filter="percent"
              testId="asset-quantity-input"
              placeholder="0%"
              disabled
            >
              <div>{{ $t('trade.card_quantity') }}</div>
            </CustomInput>

            <CustomInput
              v-else
              class="mb-s24"
              v-model="usd_to_receive"
              filter="money"
              testId="asset-dollar-amount-input"
              placeholder="$0"
              disabled
            >
              <div>{{ $t('trade.usd_to_receive') }}</div>
            </CustomInput>

            <ButtonV2
              :label="$t('trade.review_order')"
              version="primary"
              :inactive="!can_review_order"
              size="medium"
              :wide="true"
              data-testid="review-order-button"
              @onClick="validateReviewOrder"
            />
          </div>
        </template>

        <InfoPanel
          v-if="is_type_instant"
          class="mt-s24"
          :title="$t(is_mode_buy ? 'trade.why_do_asset_prices' : 'trade.why_cant_i_sell')"
        >
          <!-- Info Panel for Buy -->
          <template v-if="is_mode_buy">
            {{ $t('trade.buy_now_causes_asset') }}
            <span
              @click="changeTypesTab('Limit Order')"
              class="text-text-positive cursor-pointer"
              >{{ $t('trade.place_a_limit_order') }}</span
            >
            {{ $t('trade.to_set_a_desired_asset_price') }}
          </template>

          <!-- Info Panel for Sell -->
          <template v-else>
            {{ $t('trade.selling_now_causes') }}
            <span
              @click="changeTypesTab('Limit Order')"
              class="text-text-positive cursor-pointer"
              >{{ $t('trade.place_a_limit_order') }}</span
            >
            {{ $t('trade.to_set_a_desired_asset_price') }}
          </template>
        </InfoPanel>
      </div>
    </template>

    <!-- Review Order -->
    <template v-else-if="show_review_order">
      <div class="flex flex-col align-center justify-center">
        <ReviewOrder
          v-bind="reviewOrderInfo"
          :isLoading="isTradeLoading"
          @onContinue="confirmTrade"
          @onCancel="cancelTrade"
          @onTooltipOpen="openTooltip"
        />

        <InfoPanel
          v-if="is_mode_buy && is_type_instant"
          class="mt-s24"
          customIconClasses="rotate-90"
          icon="exchange-alt"
          :title="
            $t('trade.post_purchase_asset_price', {
              price: numberFormat(buy_estimate_v2.post_purchase_card_price, 2, false, true),
            })
          "
        >
          {{ $t('trade.price_change') }}:
          <span class="font-bold"> {{ numberFormat($big(buy_estimate_v2.price_change).times(100), 2, true) }}% </span>
          {{ $t('trade.price_impact') }}:
          <span class="font-bold">
            {{ $t(`trade.price_impact_${buy_estimate_v2.price_impact}`) }}
          </span>
        </InfoPanel>
      </div>
    </template>

    <!-- Trade Confirmation -->
    <template v-else-if="show_trade_confirmation">
      <div class="flex flex-col align-center justify-center">
        <OrderConfirmed
          v-bind="orderConfirmedInfo"
          @onViewed="beforeConfirmOrder"
          @onDone="afterConfirmOrder"
          @onContinue="openOrdersPage"
        />
      </div>
    </template>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { formatFraction, formatMoney, toSnakeCase } from '@/utils/formatters';
import Vue from 'vue';
import vueDebounce from 'vue-debounce';
Vue.use(vueDebounce);

import { 
  ButtonV2,
  Carousel,
  CustomInput,
  InfoPanel,
  Loading,
  OrderConfirmed,
  ReviewOrder,
  SecondaryTabs,
  TabSelector,
  ToggleList,
} from '@/components/misc';

import { 
  DEFAULT_STATE,
  DEFAULT_MODES,
  DEFAULT_TYPES
} from '@/modules/trade';

import { IMAGE_SIZES } from '@/components/misc/ImageResize/ImageResize.vue';

export default {
  name: 'TradeWidgetV2',
  
  components: {
    ButtonV2,
    Carousel,
    CustomInput,
    InfoPanel,
    Loading,
    OrderConfirmed,
    ReviewOrder,
    SecondaryTabs,
    TabSelector,
    ToggleList,
  },
  
  data() {
    return {
      show_preset_amount: true,
          
      custom_amount_input: 0,
      selected_custom_amount: null,
      
      limit_buy_input: 0,
      selected_limit_buy: null,

      limit_sell_input: 0,
      selected_limit_sell: null,
      
      selected_preset_item: null,
      asset_price: 0,

      card_quantity: 0,
      usd_to_receive: 0,
      send_amount: 0,
    };
  },

  props: {
    asset: {
      type: Object,
      required: true,
    },
    images: Array,
    loading: {
      type: Boolean,
      default: false,
    },
    initialMode: {
      type: String,
      default: 'buy_card',
    },
    hideTabSelector: {
      type: Boolean,
      default: false,
    },
  },
  
  computed: {
    ...mapState('api', ['current_user']),
    ...mapState('ui', ['is_mobile']),
    ...mapState('trade', Object.keys(DEFAULT_STATE)),
    ...mapGetters('trade', ['orderConfirmedInfo', 'reviewOrderInfo']),
    
    asset_name() {
      return this.lodashGet(this.asset, 'base.name', '');
    },
    
    asset_amount() {
      return this.lodashGet(this.asset, 'base.current_user_amount', 0);
    },

    sending_balance() {
      return this.is_mode_buy ? this.user_balance : this.asset_amount;
    },

    is_authenticated(){
      return this.$store.state.api.isAuthenticated;
    },

    asset_property() {
      const specs = this.lodashGet(this.asset, 'base.specs', []);
      const asset_specs = specs.reduce((accum, spec) => ({
        ...accum,
        [`asset_${ toSnakeCase(spec.key) }`]: spec.value
      }), {});

      return {
        ...asset_specs,
        base_name: this.lodashGet(this.asset, 'base.name', ''),
        base_supply: this.lodashGet(this.asset, 'base.supply', ''),
        base_ticker: this.lodashGet(this.asset, 'base.ticker', ''),
        id_hr: this.lodashGet(this.asset, 'id_hr',''),
        id: this.lodashGet(this.asset,'id', ''),
        market_cap: this.lodashGet(this.asset, 'market_cap', ''),
        player_id_hr: this.lodashGet(this.asset, 'base.associated_players[0].id_hr', ''),
        player_id: this.lodashGet(this.asset, 'base.associated_players[0].id', ''),
        price_change_24h: this.lodashGet(this.asset, 'price_change_24h', ''),
        quote_name: this.lodashGet(this.asset, 'quote.name', ''),
        quote_ticker: this.lodashGet(this.asset, 'quote.ticker', ''),
        volume_base_change_24h: this.lodashGet(this.asset, 'volume_base_24h', ''),
        volume_quote_change_24h: this.lodashGet(this.asset, 'volume_quote_24h', ''),
      };
    },

    asset_size() {
      return IMAGE_SIZES['trade_widget'];
    },

    buy_estimate_params() {
      const variables = {
        pair: this.asset.id_hr,
        usd_purchased: this.$big(this.selected_buy_preset.usd_amount_spent),
        card_buy_price: this.$big(this.selected_buy_preset.average_card_price_purchase),
      };

      if (!this.show_preset_amount) {
        variables['usd_purchased'] = this.custom_amount_input;
        variables['card_buy_price'] = this.asset.base.price_usd;
      }

      return this.buy_estimate_v2 ? variables : {};
    },

    buy_preset_headers() {
      return [
        this.$t('trade.purchase_amount'),
        this.$t('trade.card_quantity'),
        this.$t('trade.card_price'),
      ];
    },

    buy_preset_items() {
      const presets = [];
      this.market_buy_presets.map(item => {
        presets.push([
          this.numberFormat(item.usd_amount_spent, 2, false, true),
          formatFraction(item.card_fraction_purchased),
          this.numberFormat(item.post_purchase_card_price, 2, false, true),
        ]);
      });
      return presets;
    },

    can_review_order() {
      return Boolean(this[`${this.current_mode}_estimate_v2`].estimate_row_id) && !this.isTradeLoading;
    },

    current_mode() {
      return this.is_mode_buy ? 'buy' : 'sell';
    },

    current_type() {
      return String(this.trade_type).toLowerCase();
    },

    current_user_balance() {
      return this.$big(this.lodashGet(this.current_user, 'usd_balance.amount', 0));
    },

    custom_amount_values() {
      const buy_values = this.limit_buy_presets.map(item => formatMoney(item, 0));
      const sell_values = this.limit_sell_presets.map(item => formatFraction(item, 0));
      if (this.current_user) {
        buy_values.push('MAX');
      }
      return this.is_mode_buy ? buy_values : sell_values;
    },

    formated_current_price() {
      return this.numberFormat(this.asset.base.price_usd, 2, false, true);
    },
    
    has_ownership() {
      return this.$big(this.lodashGet(this.asset, 'base.current_user_amount', 0)).gt(0);
    },

    has_presets() {
      return Boolean(this.preset_items.length);
    },

    is_mode_buy() {
      return this.trade_mode === DEFAULT_MODES.BUY;
    },

    is_type_instant() {
      return this.trade_type === DEFAULT_TYPES.INSTANT;
    },

    limit_buy_estimate_params() {
      if (!this.limit_buy_input || !this.asset_price) {return;}

      return {
        pair: this.asset.id_hr,
        usd_purchased: this.limit_buy_input,
        card_buy_price: this.asset_price,
      };
    },

    limit_sell_estimate_params() {
      if (!this.limit_sell_input || !this.asset_price) {return;}

      return {
        pair: this.asset.id_hr,
        card_amount_fraction: this.$big(this.limit_sell_input).div(100).times(this.asset.base.current_user_amount),
        card_sell_price: this.asset_price,
      };
    },
    
    modes_tabs() {
      const modes = ['buy_card'];

      if (this.has_ownership) {
        modes.push('sell_card');
      }

      return modes;
    },

    preset_headers() {
      return this.is_mode_buy ? this.buy_preset_headers : this.sell_preset_headers;
    },
    
    preset_items() {
      return this.is_mode_buy ? this.buy_preset_items : this.sell_preset_items;
    },

    selected_mode() {
      return this.is_mode_buy ? 'buy_card' : 'sell_card';
    },

    selected_type() {
      const content = this.trade_type === DEFAULT_TYPES.INSTANT ? `${this.current_mode}_now` : 'limit_order';
      return this.$t(`navigation.${content}`);
    },

    sell_estimate_params() {
      const variables = {
        pair: this.asset.id_hr,
        card_amount_fraction: this.$big(this.selected_sell_preset.card_fraction_sold),
        card_sell_price: this.$big(this.selected_sell_preset.average_card_price_sold),
      };

      if (!this.show_preset_amount) {
        variables['card_amount_fraction'] = this.$big(this.custom_amount_input).div(100);
        variables['card_sell_price'] = this.asset.base.price_usd;
      }

      return this.sell_estimate_v2 ? variables : {};
    },

    sell_preset_headers() {
      return [
        this.$t('trade.sell_amount'),
        this.$t('trade.quantity'),
        this.$t('trade.asking_price'),
      ];
    },

    sell_preset_items() {
      const presets = [];
      this.market_sell_presets.map(item => {
        presets.push([
          this.numberFormat(item.usd_amount_received, 2, false, true),
          formatFraction(item.inventory_percentage_sold, 2),
          this.numberFormat(item.post_sale_card_price, 2, false, true),
        ]);
      });
      return presets;
    },
    
    types_tabs() {
      const buy_types = ['buy_now', 'limit_order'];
      const sell_types = ['sell_now', 'limit_order'];
      return this.is_mode_buy ? buy_types : sell_types;
    },

    user_balance() {
      return this.lodashGet(this.current_user, 'usd_balance.amount', 0);
    },
  },

  watch: {
  
    buy_estimate_v2(val, oldVal) {
      if (val === oldVal || this.trade_type !== 'LIMIT') {return;}
      this.card_quantity = this.$big(val.card_fraction_purchased || 0).times(100).toFixed(2);
      this.send_amount = this.$big(val.usd_amount_spent || 0).toFixed(2);
    },
    sell_estimate_v2(val, oldVal) {
      if (val === oldVal || this.trade_type !== 'LIMIT') {return;}
      this.usd_to_receive = this.$big(val.usd_amount_received || 0).toFixed(2);
    },
    asset(val, oldVal) {
      if (val !== oldVal) {
        this.$nextTick(() => {
          this.getPresets();
        });
      }
    },
    globalIsLoading(newValue, oldValue) {
      if (oldValue || !newValue) {
        this.$nextTick(() => {
          this.loaded();
        });
      }
    },
  },
  
  methods: {
    ...mapActions('user', ['requiresKycComplete']),
    ...mapActions('ui', ['showToast']),

    loaded() {
      this.getPresets();
      this.$store.dispatch('events/track', {
        event: 'ASSET_CHECKOUT_VIEWED',
        variables: {
          ... this.asset_property,
          transaction_type: this.current_mode,
        },
      });
    },

    async beforeConfirmOrder() {
      const estimate = this[`${this.current_mode}_estimate_v2`];
      const quote_amount = this.is_mode_buy ? 'usd_amount_spent' : 'usd_amount_received';
      const base_amount = this.is_mode_buy ? 'card_fraction_purchased' : 'card_fraction_sold';

      this.$store.dispatch('events/track', {
        event: 'ASSET_CHECKOUT_RECEIPT_VIEWED',
        variables: {
          ... this.asset_property,
          base_amount: this.lodashGet(estimate, quote_amount, null),
          quote_amount: this.lodashGet(estimate, base_amount, null),
          base_remaining_balance: !this.is_mode_buy ? this.$big(this.sending_balance).minus(this.usd_to_receive) : null,
          quote_remaining_balance: this.is_mode_buy ? this.$big(this.sending_balance).minus(this.send_amount) : null,
          transaction_type: this.current_mode,
          order_type: this.current_type,
          limit_price: !this.is_type_instant ? this[`limit_${this.current_mode}_input`] : null,
          fee_amount: this.lodashGet(this[`${this.current_mode}_estimate_v2`], 'fee', null),
        },
      });

      await this.$emit('refreshPageData');
    },

    async afterConfirmOrder() {
      await this.$store.dispatch('trade/reset');
      this.getPresets();
      this.show_preset_amount = true;
      this.selected_preset_item = null;
    },

    calculateEstimate() {
      const action = this.is_mode_buy ? 'getBuyEstimate' : 'getSellEstimate';
      const variables = this.is_mode_buy ? this.buy_estimate_params : this.sell_estimate_params;
      if (this.selected_buy_preset || this.selected_sell_preset) {
        this.$store.dispatch(`trade/${action}`, {
          apolloApiCall: this.apolloApiCall,
          showError: this.showError,
          variables,
        });
      }
    },

    cancelTrade() {
      this.$store.dispatch('trade/showReviewOrder', false);
      this.$store.dispatch('trade/reset', true);
      this.resetValues();
      this.getPresets();
    },

    changeModesTab(new_tab) {
      const mode = new_tab === 'buy_card' ? DEFAULT_MODES.BUY : DEFAULT_MODES.SELL;

      if (this.trade_mode !== mode) {
        this.$store.dispatch('trade/changeMode', mode);
        this.$store.dispatch('trade/changeType', DEFAULT_TYPES.INSTANT);
        this.show_preset_amount = true;
        this.$store.dispatch('trade/reset', true);
        this.selected_preset_item = null;
        this.resetValues();
        this.emitTypeChangeEvent();
      }
    },
    
    changeTypesTab(new_tab) {
      const selected_type = this.$t('navigation.limit_order');
      const type = new_tab === selected_type ? DEFAULT_TYPES.LIMIT : DEFAULT_TYPES.INSTANT;

      if (this.trade_type !== type) {
        this.show_preset_amount = type !== DEFAULT_TYPES.LIMIT;
        this.$store.dispatch('trade/changeType', type);
        this.$store.dispatch('trade/reset', true);
        this.selected_preset_item = null;
        this.resetValues();
        this.emitTypeChangeEvent();
      }
    },

    emitTypeChangeEvent() {
      this.$store.dispatch('events/track', {
        event: 'ASSET_CHECKOUT_ORDER_TYPE_CHANGED',
        variables: {
          ... this.asset_property,
          type: `${this.current_type} ${this.current_mode}`,
        },
      });
    },

    confirmTrade() {
      const action = this.is_mode_buy ? 'buyV2' : 'sellV2';
      const node = this.is_mode_buy ? 'buy_estimate_v2' : 'sell_estimate_v2';
      const row_id = this[node].estimate_row_id;
      const estimate = this[`${this.current_mode}_estimate_v2`];
      const quote_amount = this.is_mode_buy ? 'usd_amount_spent' : 'usd_amount_received';
      const base_amount = this.is_mode_buy ? 'card_fraction_purchased' : 'card_fraction_sold';

      this.$store.dispatch(`trade/${action}`, {
        apolloApiCall: this.apolloApiCall,
        showError: this.showError,
        variables: {
          estimate_row_id: row_id,
        },
      });

      this.$store.dispatch('events/track', {
        event: 'ASSET_CHECKOUT_SUBMITTED',
        variables: {
          ...this.asset_property,
          base_amount: this.lodashGet(estimate, quote_amount, null),
          quote_amount: this.lodashGet(estimate, base_amount, null),
          base_remaining_balance: !this.is_mode_buy ? this.$big(this.sending_balance).minus(this.usd_to_receive) : null,
          quote_remaining_balance: this.is_mode_buy ? this.$big(this.sending_balance).minus(this.send_amount) : null,
          transaction_type: this.current_mode,
          order_type: this.current_type,
          limit_price: !this.is_type_instant ? this[`limit_${this.current_mode}_input`] : null,
          fee_amount: this.lodashGet(this[`${this.current_mode}_estimate_v2`], 'fee', null),
        }
      });
    },

    getPresets() {
      if(this.asset.id_hr) {
        this.$store.dispatch('trade/getBuyPresets', { 
          apolloApiCall: this.apolloApiCall,
          showError: this.showError,
          variables: { pair: this.asset.id_hr }
        });
      }
      
      if (this.has_ownership) {
        this.$store.dispatch('trade/getSellPresets', { 
          apolloApiCall: this.apolloApiCall,
          showError: this.showError,
          variables: { 
            pair: this.asset.id_hr,
            max_card_amount: this.$big(this.asset.base.current_user_amount).toNumber()
          }
        });
      }
      
      this.$store.dispatch('trade/getLimitPresets', {
        apolloApiCall: this.apolloApiCall,
        showError: this.showError,
      });
    },

    async openOrdersPage() {
      this.goToInternalPageGlobal('/wallet');
    },

    openTooltip(tooltip) {
      switch (tooltip) {
        case 'average_asset_price':
          this.$store.dispatch('ui/getTooltips', {
            apolloApiCall: this.apolloApiCall,
            showError: this.showError,
            variables: {
              keys: ['average_price', 'limit_order', 'card_price'],
            },
          });
          break;
        case 'asset_price':
          this.$store.dispatch('ui/getTooltips', {
            apolloApiCall: this.apolloApiCall,
            showError: this.showError,
            variables: {
              keys: ['card_price', 'average_price', 'limit_order'],
            },
          });
          break;
        case 'limit_order':
          this.$store.dispatch('ui/getTooltips', {
            apolloApiCall: this.apolloApiCall,
            showError: this.showError,
            variables: {
              keys: ['limit_order', 'card_price', 'average_price'],
            },
          });
          break;
      }
    },

    promptPriceAlert(isBuying) {
      const type = isBuying ? 'buy' : 'sell';

      this.$store.commit('ui/showPrompt', {
        eventName: 'trade__set-limit-order',
        title: this.$t('trade.price_impact_alert'),
        message: this.$t(`trade.the_value_of_the_order_${type}`),
        customConfirm: this.$t('trade.set_limit_order'),
      });
    },

    resetValues() {
      // INSTANT BUY/SELL
      this.custom_amount_input = 0;
      this.selected_custom_amount = null;

      // LIMIT BUY
      this.limit_buy_input = 0;
      this.selected_limit_buy = null;
      this.asset_price = 0;
      this.card_quantity = 0;
      this.selected_preset_item = null;

      // LIMIT SELL
      this.limit_sell_input = 0;
      this.selected_limit_sell = null;
    },

    selectPreset(i) {
      this.selected_preset_item = this.preset_items[i];

      const selectedPreset = this[`market_${this.current_mode}_presets`][i];
      const selectPresetAction = this.is_mode_buy ? 'selectBuyPreset' : 'selectSellPreset';
      const setEstimateAction = this.is_mode_buy ? 'setBuyEstimate' : 'setSellEstimate';

      this.$store.dispatch(`trade/${selectPresetAction}`, selectedPreset);
      this.$store.dispatch(`trade/${setEstimateAction}`, selectedPreset);

      this.$store.dispatch('events/track', {
        event: 'ASSET_CHECKOUT_PRESET_SELECTED',
        variables: {
          ...this.asset_property,
          transaction_type: this.current_mode,
          quote_amount: selectedPreset[`${this.is_mode_buy ? 'usd_amount_spent' : 'usd_amount_received'}`],
          base_amount: selectedPreset[`${this.is_mode_buy ? 'card_fraction_purchased' : 'card_fraction_sold'}`],
          post_purchase_price:
            selectedPreset[`${this.is_mode_buy ? 'post_purchase_card_price' : 'post_sale_card_price'}`],
          current_preset_index: i + 1,
          total_preset_count: this.preset_items.length,
          fee: this.lodashGet(selectedPreset, 'fee', null),
        },
      });
    },

    deselectPreset() {
      this.selected_preset_item = null;
      this.$store.dispatch('trade/reset', true);
    },

    submitReviewOrder() {
      const estimate = this[`${this.current_mode}_estimate_v2`];
      const quote_amount = this.is_mode_buy ? 'usd_amount_spent' : 'usd_amount_received';
      const base_amount = this.is_mode_buy ? 'card_fraction_purchased' : 'card_fraction_sold';

      this.$store.dispatch('trade/showReviewOrder', true);
      this.$store.dispatch('events/track', {
        event: 'ASSET_CHECKOUT_ORDER_VIEWED',
        variables: {
          ...this.asset_property,
          base_amount: this.lodashGet(estimate, quote_amount, null),
          quote_amount: this.lodashGet(estimate, base_amount, null),
          base_remaining_balance: !this.is_mode_buy ? this.$big(this.sending_balance).minus(this.usd_to_receive) : null,
          quote_remaining_balance: this.is_mode_buy ? this.$big(this.sending_balance).minus(this.send_amount) : null,
          transaction_type: this.current_mode,
          order_type: this.current_type,
          limit_price: !this.is_type_instant ? this[`limit_${this.current_mode}_input`] : null,
          fee_amount: this.lodashGet(this[`${this.current_mode}_estimate_v2`], 'fee', null),
        },
      });
    },

    toggleCustomAmount() {
      this.resetValues();
      this.show_preset_amount = !this.show_preset_amount;
      if (this.show_preset_amount) {
        this.getPresets();
      }
    },

    updateCustomAmount(value) {
      const amount = value.toString().replace(/[$%]/g, '').trim();
      
      this.selected_custom_amount = (parseInt(value)).toString();

      // Fixed option value
      const finalAmount = this.is_mode_buy ? amount:parseFloat(amount);
      this.custom_amount_input = amount === 'MAX' ? this.$big(this.user_balance).toFixed(2) : finalAmount;

      this.calculateEstimate();

      this.$store.dispatch('events/track', {
        event: 'ASSET_CHECKOUT_CUSTOM_AMOUNT',
        variables: {
          ...this.asset_property,
          transaction_type: this.current_mode,
          [`${this.is_mode_buy ? 'custom_quote_amount' : 'custom_percentage_amount'}`]: this.numberFormat(
            this.custom_amount,
            2,
            !this.is_mode_buy,
            this.is_mode_buy
          ),
        },
      });
    },

    updateLimitAmount(value) {
      const amount = String(value).replace(/[$%]/g, '').trim();
      const formated_value = this.is_mode_buy
        ? this.$big(this.user_balance).toFixed(2)
        : this.$big(this.asset.base.current_user_amount).toFixed(2);

      if (this.is_mode_buy) {
        this.selected_limit_buy = value;
        this.limit_buy_input = amount === 'MAX' ? formated_value : amount;
      } else {
        this.selected_limit_sell = value;
        this.limit_sell_input = this.$big(amount).toFixed(2);
      }

      this.calculateLimit();
    },

    async updatePresets() {
      const action = this.is_mode_buy ? 'getBuyPresets' : 'getSellPresets';
      const custom_amount = this.is_mode_buy
        ? { usd_custom_input: this.$big(this.custom_amount_input).toNumber() }
        : {
            custom_percentage_input: this.$big(this.custom_amount_input).div(100).toNumber(),
            max_card_amount: this.$big(this.asset.base.current_user_amount).toNumber(),
          };

      const response = await this.$store.dispatch(`trade/${action}`, {
        apolloApiCall: this.apolloApiCall,
        showError: this.showError,
        variables: {
          ...custom_amount,
          pair: this.asset.id_hr,
        },
      });

      if (response) {
        this.selectPreset(0);
        this.show_preset_amount = true;
      }
    },

    calculateLimit() {
      const action = this.is_mode_buy ? 'getBuyEstimate' : 'getSellEstimate';
      const variables = this.is_mode_buy ? this.limit_buy_estimate_params : this.limit_sell_estimate_params;

      if (variables) {
        this.$store.dispatch(`trade/${action}`, {
          apolloApiCall: this.apolloApiCall,
          showError: this.showError,
          variables: { ...variables },
        });
      }
    },

    async validateReviewOrder() {
      try {
        await this.requiresKycComplete();

        // if user balance is lower than asset price
        if (
          (this.is_type_instant &&
            this.is_mode_buy &&
            this.current_user_balance.lt(this.selected_buy_preset.usd_amount_spent)) ||
          (!this.is_type_instant && this.current_user_balance.lt(this.limit_buy_input))
        ) {
          return this.$store.commit('ui/showPrompt', {
            eventName: 'trade__add-funds',
            title: this.$t('trade.insufficient_balance'),
            message: this.$t('trade.please_make_sure_you_have'),
            customConfirm: this.$t('wallet.add_funds'),
          });
        }

        // if buy causes high price impact
        if (this.is_mode_buy && this.selected_buy_preset.is_invalid) {
          this.promptPriceAlert(this.is_mode_buy);

          return this.$store.dispatch('events/track', {
            event: 'ASSET_CHECKOUT_PRICE_ALERT_VIEWED',
            variables: {
              ...this.asset_property,
            },
          });
        }

        // if sell causes high price impact
        if (!this.is_mode_buy && this.selected_sell_preset.is_invalid) {
          this.promptPriceAlert(this.is_mode_buy);

          return this.$store.dispatch('events/track', {
            event: 'ASSET_CHECKOUT_PRICE_ALERT_VIEWED',
            variables: {
              ...this.asset_property,
            },
          });
        }

        // it's ok to trade
        this.submitReviewOrder();
      } catch (err) {
        await this.$store.dispatch('ui/showError', err);
      }
    },
  },

  mounted() {
    this.getPresets();

    this.$root.$on('trade__set-limit-order', () => {
      this.changeTypesTab('Limit Order');
    });

    this.$root.$on('trade__add-funds', () => {
      this.showModal('PaymentInterface', { isDeposit: true });
    });

    if (this.$route.params.action === 'sell' || this.initialMode === 'sell_card') {
      if (this.$big(this.asset_amount).gt(0)) {
        this.changeModesTab('sell_card');
      } else {
        this.$router.push({ name: 'trade' });
      }
    }
  },

  beforeDestroy() {
    this.$root.$off('trade__set-limit-order');
    this.$root.$off('trade__add-funds');
    this.$store.dispatch('trade/reset');
  },
};
</script>

<style scoped>
.carousel-container {
  @apply flex justify-center mb-s24;
}
</style>
