<template>
  <div class="flex justify-between items-center text-xl20 font-semibold mt-3">
    <div>{{spec.key}}</div>
    <div class="text-text-body">{{spec.value}}</div>
  </div>
</template>

<script>
export default {
  name: 'Asset',
  props: {
    spec: Object,
  },
};
</script>

<style scoped>
  .assets-container {
    @apply bg-background-primary p-7-5 border border-border;
    border-radius: 25px;
  }
</style>
