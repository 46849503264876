<template>
  <Collapse
    v-if="trades.length"
    class="bg-background-primary border border-border rounded-24"
    :customClasses="collapseClasses"
  >
    <div class="text-left text-n-4xl font-bold md:text-n-3xl" slot="title">{{ $t('trade.recent_orders') }}</div>

    <div class="w-full flex text-sm14 font-semibold mt-4 mb-2 xs:text-sm12">
      <div class="w-1/3">{{ $t('trade.price_usd') }}</div>
      <div class="w-1/3 text-right">{{ $t('trade.amount') }}</div>
      <div class="w-1/3 text-right">{{ $t('trade.total') }}</div>
    </div>

    <div v-for="(trade, i) in trades" :key="i"
      :class="{
        'text-text-positive': (trade.type === 'orderbuy' || trade.type === 'ammbuy'),
        'text-text-negative': (trade.type === 'ordersell' || trade.type === 'ammsell')
      }"
      class="w-full flex trade-row font-monospace text-n-lg font-semibold mt-1 xs:text-sm12"
    >
      <div class="w-1/3">{{ numberFormat(trade.price) }}</div>
      <div class="w-1/3 text-right">{{ numberFormat(trade.amount, 6) }}</div>
      <div class="w-1/3 text-right">{{ numberFormat($big(trade.price).times(trade.amount)) }}</div>
    </div>
  </Collapse>
</template>

<script>
import Collapse from '@/components/misc/Collapse/Collapse';

export default {
  name: 'RecentTrades',
  components: {
    Collapse
  },
  props: {
    trades: Array,
  },
  data() {
    return {
      collapseClasses: {
        button: 'p-s24',
        content: 'px-s24',
        contentActive: 'pb-s24',
      },
    };
  },
};
</script>

<style scoped>
  .recent-trades {
    border-radius: 15px;
  }
</style>
