<template>
    <div class="flex align-center gap-n-md justify-between my-4 w-full">
      <div class="flex flex-col gap-n-md sm:max-w-1/2 sm:items-start">
       <SkeletonLoading v-if="isLoading" class="max-w-full" borderRadius="0" width="130px" height="24px" />
       <div ref="priceContent" v-else class="text-text-inactive sm:text-n-md text-n-lg">
          {{$t('asset_price_details_title')}}
        </div>
        <SkeletonLoading v-if="isLoading" class="max-w-full" borderRadius="0" width="215px" height="45px" />
        <div v-else class="sm:text-n-xl text-n-5xl font-bold">
          $<AnimatedNumber :number="price"/>
        </div>
      </div>
      <div class="flex flex-col gap-n-md sm:max-w-1/2 xl:items-end">
        <SkeletonLoading v-if="isLoading" class="max-w-full" borderRadius="0" width="130px" height="24px" />
        <div v-else class="text-text-inactive sm:text-n-md text-n-lg xl:text-right text-left">
          {{$t('asset_price_details_fluctuation_title')}}
        </div>
        <SkeletonLoading class="max-w-full" v-if="isLoading" borderRadius="0" width="215px" height="45px" />
        <div v-else class="flex align-baseline lg:justify-end" :class="fluctuationColor">
          <div class="sm:text-n-xl text-n-5xl font-bold mr-2">
            <AnimatedNumber :number="fluctuationValue" :signal="true"/>
          </div>
          <div class="flex flex-col justify-end sm:text-n-lg text-n-3xl font-medium">
            <p>
              (<AnimatedNumber :number="fluctuationPercentage" :signal="true"/>%)
            </p>
          </div>
        </div>
      </div>
    </div>
</template>

<script> 
import AnimatedNumber from '@/components/misc/AnimatedNumber.vue';

export default {
  name: 'AssetPriceDetails',
  data:() => ({
    priceSkeletonLoadingWidth: '0px',
    fluctuationSkeletonLoadingWidth: '0px',
  }),
  components: {
    AnimatedNumber,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: () => false
    },
    price: {
      type: Number,
      default: () => 0
    },
    fluctuationValue: {
      type: Number,
      default: () => 0
    },
    fluctuationPercentage: {
      type: Number,
      default: () => 0
    }
  },
  computed: {
    fluctuationColor(){
      if (this.fluctuationPercentage < 0) {
        return 'text-text-negative';
      }
      return 'text-text-positive';
    }
  }
};
</script>

<style scoped>
</style>
