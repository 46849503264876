<template>
  <div v-if="specs && specs.length" class="standard-container-v2">
    <div class="w-full text-xl32 font-bold mb-4">{{ $t('asset.specs') }}</div>

    <Asset
      v-for="(n, index) in specs.length <= maxSpecs ? specs.length : maxSpecs"
      v-bind:key="index"
      :spec="specs[index]"
    />
    <div>
      <Button
        v-if="specs.length > maxSpecs"
        @buttonAction="removeSpecsLimit"
        :label="`${$t('asset.complete_specs')}`"
        version="v7"
        customClasses="mt-4"
      />
    </div>
  </div>
</template>

<script>
import Asset from '@/components/trade/Asset';
import Button from '@/components/misc/Button';

export default {
  name: 'AssetSpecs',
  components: {
    Asset,
    Button,
  },

  props: {
    specs: Array,
  },

  data() {
    return {
      maxSpecs: 9
    };
  },

  methods: {
    removeSpecsLimit() {
      this.maxSpecs = 999;
    }
  }
};
</script>