<template>
  <TradeCard 
    v-if="shouldShow"
    :isLoading="isSkeletonLoading"
    :skeletonLoading="skeletonOptions">
      <div class="flex justify-between items-center" slot="header">
        <div class="flex justify-center items-center leading-none">
          <div class="text-n-4xl font-bold lg:text-n-3xl">
            {{ $t('cardladder.title') }}
          </div>
          <IconInfo
            @click="openHelpDialog"
            class="w-s16 h-s16 ml-s8 fill-current cursor-pointer default-transition hover:opacity-60"
          />
        </div>
        <div class="mt-n-2xl lg:hidden block">
          <ButtonV2
            version="secondary"
            size="medium"
            @onClick="openCardladderPage(seeAllUrl)"
            :label="$t('cardladder.see_all')"
            testId="btn-see-all2"
          />
        </div>
      </div>

      <div class="flex flex-col">
        <div class="flex justify-start items-center lg:mt-n-xl lg:mb-n-3xl mt-n-3xl leading-none order-last lg:order-first">
          <div class="text-n-sm font-semibold sm:text-n-xs">
            {{ $t('cardladder.last_days') }} {{ $t('cardladder.data_provided') }}
          </div>
          <IconCardLadder class="cardladder-icon h-n-5xl ml-n-md text-text-body fill-current sm:h-n-4xl" />
        </div>

        <div class="grid grid-cols-3 gap-s24 items-center mt-2">
          <template v-for="(item) of content">
            <div v-if="item.active" :key="item.id" class="flex flex-col wrap font-semibold">
              <p class="text-text-inactive lg:body-text-x-medium body-text-x-large mb-s4 xl:mt-s2">{{ item.title }}</p>
              <p :class="['lg:subheadline-medium subheadline-large', item.specialClasses]">{{ item.body }}</p>
            </div>
          </template>
        </div>

        <div class="mt-n-2xl lg:block hidden">
          <ButtonV2
            version="secondary"
            size="medium"
            @onClick="openCardladderPage(seeAllUrl)"
            :label="$tc('cardladder.see_all')"
            testId="btn-see-all"
          />
        </div>
      </div>
  </TradeCard>
</template>

<script>
import { IconCardLadder, IconInfo } from '@/assets/icons';
import ButtonV2 from '@/stories/misc/ButtonV2';
import TradeCard from '@/components/trade/TradeCard';

export default {
  name: 'CardLadder',
  data: () => ({
    skeletonOptions: {
      height: '320px'
    }
  }),
  components: {
    ButtonV2,
    IconCardLadder,
    IconInfo,
    TradeCard,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    cardladder: Object,
    isSkeletonLoading:  {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    seeAllUrl() {
      return `${this.cardladder.cl_url}&ref=dibbs_web`;
    },
    shouldShow() {
      return this.isSkeletonLoading ? true : this.isVisible && this.lodashGet(this.cardladder, 'cl_url', false);
    },
    content(){
      return [
        {
          id: 0,
          title: this.$t('cardladder.last_price'), 
          body: `${this.numberFormat(this.lodashGet(this.cardladder, 'cl_market_value', 0), 2, false, true)}`,
          active: !!this.lodashGet(this.cardladder, 'cl_market_value', 0)
        },
        {
          id: 1,
          title: this.$t('cardladder.average_price'), 
          body: `${this.numberFormat(this.lodashGet(this.cardladder, 'cl_average_price', 0), 2, false, true)}`,
          active: !!this.lodashGet(this.cardladder, 'cl_average_price', 0)
        },
        {
          id: 2,
          title: this.$t('cardladder.highest_price'), 
          body: `${this.numberFormat(this.lodashGet(this.cardladder, 'cl_high_price', 0), 2, false, true)}`,
          active: !!this.lodashGet(this.cardladder, 'cl_high_price', 0)
        },
        {
          id: 3,
          title: this.$t('cardladder.lowest_price'), 
          body: `${this.numberFormat(this.lodashGet(this.cardladder, 'cl_low_price', 0), 2, false, true)}`,
          active: !!this.lodashGet(this.cardladder, 'cl_low_price', 0)
        },
        {
          id: 4,
          title: this.$t('cardladder.number_of_sales'), 
          body: `${this.lodashGet(this.cardladder, 'cl_num_sales', 0)}`,
          active: !!this.lodashGet(this.cardladder, 'cl_num_sales', 0)
        },
        {
          id: 5,
          title: this.$t('cardladder.population'), 
          body: `${this.lodashGet(this.cardladder, 'cl_population', 0)}`,
          active: !!this.lodashGet(this.cardladder, 'cl_population', 0)
        },
      ];
    },
  },
  methods: {
    async openCardladderPage(page) {
      this.goToExternalPageGlobal(page);
      await this.$store.dispatch('events/track', {
        event: 'CARD_LADDER_VIEWED',
      });
    },
    openHelpDialog() {
      this.showModal('CardLadderHelp');
    },
  }
};
</script>

<style scoped>
  .cardladder-container {
    @apply flex-1 bg-background-primary rounded-16 border border-border;
  }

  .cardladder-icon {
    height: 20px;
  }
</style>
