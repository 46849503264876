<template>
  <TradeCard v-if="currentUserAmount > 0" :isLoading="isSkeletonLoading" :skeletonLoading="skeletonOptions">
    <div class="flex justify-between items-center" slot="header">
      <div class="flex items-center">
        <h3 :class="headerClasses">{{ $t('trade.balance') }}</h3>
        <IconInfo @click="$emit('openAssetLevelModal')" class="w-s16 h-s16 ml-s8 text-text-body fill-current cursor-pointer default-transition hover:opacity-60"/>
      </div>
      <div class="subheadline-large">${{ numberFormat(balance) }}</div>
    </div>

    <div class="grid gap-s8 items-center" :class="gridClasses">
      <template v-for="(item) of values">
        <div v-if="item.isVisible" :key="item.id" class="flex flex-col wrap font-semibold">
          <p class="text-text-inactive" :class="itemTitleClasses">{{ item.title }}</p>
          <p :class="['mt-s4', 'xl:mt-s2', itemBodyClasses, item.specialClasses]">{{ item.body }}</p>
        </div>
      </template>
    </div>

    <template v-if="!isDrop">
      <div class="grid gap-s8 items-center mt-s24" :class="gridClasses">
        <template v-for="(item) of profit">
          <div :key="item.id" class="flex flex-col wrap font-semibold">
            <p class="text-text-inactive" :class="itemTitleClasses">{{ item.title }}</p>
            <p :class="['mt-s4', 'xl:mt-s2', item.specialClasses]">
              <span :class="itemBodyClasses">{{ item.priceChange }}</span>
              <span :class="itemPercentageClasses">{{ item.percentageChange }}</span>
            </p>
          </div>
        </template>
      </div>

      <template>
        <p v-if="!hasOwnership" class="mt-s24 body-text-medium">
          {{$t('balance.how_to_take_custody')}}
          <button
            class="font-medium balance-learnmore text-text-active-2 default-transition"
            @click="$emit('openCustodyModal')"
          >
            {{$t('balance.how_to_take_custody_learn_more')}}
          </button>
        </p>

        <section v-else class="balance-take-custody">
          <p class="block body-text-large">
            {{ $t('balance.total_ownership') }}
            <a 
              class="block body-text-large underline"
              href="https://help.dibbs.io/en/articles/5632635-take-custody-of-an-asset"
              target="_blank"
            >
              {{ $t('balance.faqs') }}
            </a>
          </p>

          <ButtonV2
            :label="$tc('balance.take_custody')"
            version="primary"
            size="medium"
            @onClick="onTakeCustody"
          />
        </section>
      </template>
    </template>
  </TradeCard>
</template>

<script>
import TradeCard from '@/components/trade/TradeCard';
import { IconInfo } from '@/assets/icons';
import { ButtonV2 } from '@/components/misc';

export default {
  name: 'Balance',
  components: {
    ButtonV2,
    TradeCard,
    IconInfo,
  },
  props: {
    currentUserAmount: [Number, String],
    currentUserCostBasis: [Number, String],
    percentChangeToday: [Number, String],
    pricePerToken: [Number, String],
    isDrop: {
      type: Boolean,
      default: false,
    },
    isSkeletonLoading:  {
      type: Boolean,
      default: false,
    },
    asset: Object,
  },
  computed: {
    balance() {
      try {
        return this.$big(this.currentUserAmount).times(this.pricePerToken);
      } catch (err) {
        return 0;
      }
    },
    currentUserPercent() {
      try {
        return this.$big(this.currentUserAmount).times(100);
      } catch (err) {
        return 0;
      }
    },
    gridClasses() {
      return {
        'card-grid': !this.isMobileDevice,
        'card-grid-mobile': this.isMobileDevice,
      };
    },
    headerClasses() {
      return {
        'headline-medium': !this.isMobileDevice,
        'headline-small': this.isMobileDevice,
      };
    },
    itemTitleClasses() {
      return {
        'body-text-x-large': !this.isMobileDevice,
        'body-text-x-medium': this.isMobileDevice,
      };
    },
    itemBodyClasses() {
      return {
        'subheadline-large': !this.isMobileDevice,
        'subheadline-small': this.isMobileDevice,
      };
    },
    itemPercentageClasses() {
      return {
        'subheadline-small': !this.isMobileDevice,
        'body-text-large': this.isMobileDevice,
      };
    },
    percentageChangeSincePurchased() {
      try {
        return this.$big(this.balance).minus(this.totalPurchased).times(100).div(this.totalPurchased);
      } catch (err) {
        return 0;
      }
    },
    priceChangeSincePurchased() {
      try {
        return this.$big(this.balance).minus(this.totalPurchased);
      } catch (err) {
        return 0;
      }
    },
    priceChangeToday() {
      try {
        return this.$big(this.percentChangeToday).times(this.balance).div(100);
      } catch (err) {
        return 0;
      }
    },
    profit(){
      return [ 
        {
          id: 0,
          specialClasses: `${this.priceChangeSincePurchased.gte(0) ? 'text-text-positive' : 'text-text-negative'}`,
          title: this.$t('trade.pct_since_purchase'), 
          priceChange: this.numberFormat(this.priceChangeSincePurchased, 2, false, true),
          percentageChange: ` (${this.numberFormat(this.percentageChangeSincePurchased, 2, true)}%)`,
        },
        {
          id: 1,
          specialClasses: `${this.$big(this.priceChangeToday).gte(0) ? 'text-text-positive' : 'text-text-negative'}`,
          title: this.$t('trade.pct_today'), 
          priceChange: this.numberFormat(this.priceChangeToday, 2, false, true),
          percentageChange: ` (${this.numberFormat(this.percentChangeToday, 2, true)}%)`,
        },
      ];
    },
    skeletonOptions() { 
      if (this.isDrop) {
        return {
          height: '161px',
        };
      }
      return {
        height: '255px',
      };
    },
    totalPurchased() {
      try {
        return this.$big(this.currentUserAmount).times(this.currentUserCostBasis);
      } catch (err) {
        return 0;
      }
    },
    values(){
      return [
        {
          id: 0,
          title: this.$t('trade.percentage_owned'), 
          body: `${this.numberFormat(this.currentUserPercent, 2, false, false)}%`,
          isVisible: true,
        },
        {
          id: 1,
          title: this.$t('trade.amount_owned'), 
          body: this.numberFormat(this.currentUserAmount, 6, false, false),
          isVisible: this.isDrop,
        },
        {
          id: 2,
          title: this.$t('trade.average_price'), 
          body: this.numberFormat(this.currentUserCostBasis, 2, false, true),
          isVisible: !this.isDrop,
        },
        {
          id: 3,
          title: this.$t('trade.total_purchased'), 
          body: this.numberFormat(this.totalPurchased, 2, false, true),
          isVisible: !this.isDrop,
        },
      ];
    },

    hasOwnership() {
      return this.currentUserPercent >= 100;
    }
  },

  methods: {
    onTakeCustody() {
      localStorage.setItem('takeCustodyAsset', JSON.stringify(this.asset));
      
      this.$router.push({ name: 'take-custody', params: { asset: this.asset } });
    },
  }
};
</script>

<style scoped>
.balance-learnmore{
  outline: 0;
}
.balance-learnmore:hover{
  @apply text-text-body;
}
.card-grid {
  grid-template-columns: 293px 293px auto;
}
.card-grid-mobile {
  grid-template-columns: auto auto auto;
}

.balance-take-custody {
  @apply flex justify-between items-center pt-s16 mt-s16;
  border-top: 1px dashed #343434;
}
</style>
