<template>
  <div v-if="!isMobileDevice">
    <AssetPriceDetails
      :isLoading="isLoading"
      :price="chartValue || data.price_per_token"
      :fluctuationValue="data.price_change_24h"
      :fluctuationPercentage="data.price_change_24h_pct"
    />

    <div class="flex mt-8-9">
      <div class="w-1/2 text-xl25 font-bold">
        {{ $t('trade.price_history') }}
      </div>

      <SecondaryTabs
        class="items-end w-1/2 justify-end"
        :currentTab="selectedChartOption"
        :tabList="tabList"
        @tabChange="(value) => changeChartRange(value)"
      />
    </div>

    <div class="upper-bound">${{ numberFormat(upperBound) }}</div>
    <Chart
      v-if="!isLoading"
      @chartSelectedValue="chartSelectedValue"
      :chartdata="chart"
      :chartOptions="chartOptions"
      chartValueLabel="close"
      excludeLeftZeros
      showTime
    />
    <div v-else class="chart-loading relative">
      <Loading version="v2" />
    </div>
    <div class="lower-bound">${{ numberFormat(lowerBound) }}</div>
  </div>
  <div v-else>
    <div v-if="isLoading">
      <SkeletonLoading height="93px" />
    </div>
    <div v-else class="flex flex-col gap-s16 mb-s32" :style="{ 'min-height': '93px' }">
      <BaseText :isLoading="isSkeletonLoading" loadingHeight="24px" size="subheadline-x-medium">
        {{ lodashGet(data, 'base.associated_players[0].name', '') }}
      </BaseText>

      <BaseText :isLoading="isSkeletonLoading" loadingHeight="24px" size="subheadline-x-medium">
        {{ lodashGet(data, 'base.name', '') }}
      </BaseText>

      <div class="flex gap-s16">
        <ButtonV2
          version="secondary"
          testId="btn-view-collection"
          size="small"
          :label="$t('trade.buttons.view_collection')"
          @onClick="$emit('goToTheColletion')"
        />
        <ButtonV2
          v-if="showSetPriceButton"
          version="secondary"
          size="small"
          :label="priceAlertLabel"
          testId="btn-price-alert"
          @onClick="$emit('setThePriceAlert')"
        />
      </div>
    </div>

    <AssetPriceDetails
      :price="chartValue || data.price_per_token"
      :isLoading="isLoading"
      :fluctuationValue="data.price_change_24h"
      :fluctuationPercentage="data.price_change_24h_pct"
    />

    <div class="flex justify-between mt-4">
      <ButtonV2
        @onClick="goToPage('buy')"
        :label="$t('player.buy')"
        testId="btn-buy"
        :wide="true"
        version="primary"
      />

      <ButtonV2
        v-if="$big(lodashGet(data, 'base.current_user_amount', 0)).gt(0)"
        @onClick="goToPage('sell')"
        :label="$t('player.sell')"
        testId="btn-sell"
        class="ml-4"
        :wide="true"
        version="primary"
      />
    </div>

    <div class="upper-bound">${{ numberFormat(upperBound) }}</div>
    <Chart
      v-if="!isLoading"
      @chartSelectedValue="chartSelectedValue"
      :chartdata="chart"
      :chartOptions="chartOptions"
      chartValueLabel="close"
      excludeLeftZeros
      showTime
    />
    <div v-else class="chart-loading relative">
      <Loading version="v2" />
    </div>
    <div class="lower-bound">${{ numberFormat(lowerBound) }}</div>

    <SecondaryTabs
      class="items-end"
      :currentTab="selectedChartOption"
      :tabList="tabList"
      @tabChange="(value) => changeChartRange(value)"
    />
  </div>
</template>

<script>
import { AssetPriceDetails } from '@/components/trade';
import { BaseText, Loading, SecondaryTabs } from '@/components/misc';
import Chart from '@/components/misc/Chart';
import ButtonV2 from '@/stories/misc/ButtonV2';
import PAIR_CHART from '@/graphql/queries/PairChart.gql';
import TRADE_CHART from '@/graphql/subscriptions/TradeChart.gql';
import chartRanges from '@/utils/chart-ranges';

export default {
  name: 'ChartContainer',
  components: {
    AssetPriceDetails,
    BaseText,
    Chart,
    SecondaryTabs,
    Loading,
    ButtonV2,
  },

  data() {
    return {
      isLoading: true,
      chartSubscription: null,
      chartValue: 0,
      chartOptions: {
        height: 375,
      },
      chart: null,
      upperBound: 0,
      lowerBound: 0,
      chartRanges: chartRanges,
      selectedChartOption: '1W',
    };
  },

  props: {
    data: Object,
    isSkeletonLoading: {
      type: Boolean,
      default: false,
    },
    priceAlertLabel: String,
    showSetPriceButton: {
      type: Boolean,
      default: false,
    },
  },

  async mounted() {
    if (!this.isSkeletonLoading) {
      await this.loaded();
    }
  },

  computed: {
    assetSpecs() {
      return this.data.base.specs.reduce(
        (accumulatedSpecs, spec) => ({
          ...accumulatedSpecs,
          [`asset_${spec.key}`]: spec.value,
        }),
        {}
      );
    },
    tabList() {
      return this.chartRanges.map((item) => item.text);
    },
  },

  watch: {
    chart() {
      if (this.chart) {
        const firstValue = this.chart.findIndex((data) => data['close'] !== 0);

        let chartValues = null;

        if (firstValue !== -1) {
          chartValues = this.chart.slice(firstValue, this.chart.length);
        }

        const values = chartValues.map((chartData) => chartData.close);
        this.upperBound = Math.max(...values);
        this.lowerBound = Math.min(...values);
      }
    },

    data() {
      this.chart = this.sanitizeChartData(this.data.chart);
    },

    isSkeletonLoading(newValue, oldValue) {
      if (oldValue || !newValue) {
        this.$nextTick(() => {
          this.loaded();
        });
      }
    },
  },

  methods: {
    loaded() {
      this.chartValue = this.lodashGet(this.data, 'chart[0].value', 0);
      this.chart = this.sanitizeChartData(this.data.chart);
      this.$emit('changeChartRange', this.chartRanges[2]);

      this.isLoading = false;
    },

    goToPage(page) {
      this.goToInternalPageGlobal(`${this.$route.path}/${page}`);
    },

    async changeChartRange(option) {
      if (this.selectedChartOption !== option) {
        this.selectedChartOption = option;

        const chartOption = this.chartRanges.find((chart) => chart.text === option);
        await this.loadData(chartOption);

        this.$emit('changeChartRange', chartOption);

        await this.$store.dispatch('events/track', {
          event: 'ASSET_CHART_TIMEFRAME_CHANGED',
          variables: {
            id: this.data.id,
            id_hr: this.data.id_hr,
            base_name: this.data.base.name,
            base_ticker: this.data.base.ticker,
            quote_name: this.data.quote.name,
            quote_ticker: this.data.quote.ticker,
            price: this.data.price_per_token,
            base_supply: this.data.base.supply,
            price_change_24h: this.data.price_change_24h,
            volume_base_change_24h: this.data.volume_base_24h,
            volume_quote_change_24h: this.data.volume_quote_24h,
            market_cap: this.data.market_cap,
            ...this.assetSpecs,
            player_id: this.data.base.associated_players[0].id,
            player_id_hr: this.data.base.associated_players[0].id_hr,
            timeframe: option,
          },
        });
      }
    },

    async loadData(chartOption) {
      try {
        this.isLoading = true;

        const response = await this.$apollo.query({
          query: PAIR_CHART,
          variables: {
            pair: this.$route.params.pair,
            chartEndTimeSeconds: parseInt(new Date().getTime() / 1000),
            window: chartOption.window,
            timeframe: chartOption.timeframe,
          },
        });

        const { chart } = response.data.pair;

        this.chart = this.sanitizeChartData(chart);
      } catch (err) {
        await this.showError(err);
      } finally {
        this.isLoading = false;
      }
    },

    chartSelectedValue(value) {
      this.chartValue = value;
    },

    async handleChartSubscription() {
      if (this.chartSubscription) {
        this.chartSubscription.unsubscribe();
      }

      const chartSubscription = await this.$apollo.subscribe({
        query: TRADE_CHART,
        variables: {
          pair: this.$route.params.pair,
          timeframe: '_15m',
        },
      });

      this.chartSubscription = chartSubscription.subscribe((response) => {
        const {
          pair_chart_change: { time, close },
        } = response.data;

        if (this.chart[this.chart.length - 1].time > time) {
          return;
        }

        if (this.chart[this.chart.length - 1].time < time) {
          this.chart = [
            ...this.chart,
            {
              time,
              close,
            },
          ];

          return this.chart;
        }

        this.chart[this.chart.length - 1].close = close;
        this.chart = [...this.chart];
      });
    },
  },
};
</script>

<style scoped>
.period-filter {
  @apply text-xl20 font-bold cursor-pointer text-center text-text-body;
  width: 45px;
}
.selected {
  @apply text-text-body;
}
.upper-bound {
  @apply mt-6 text-text-positive text-xl20 font-bold border-0 border-dotted border-b-4 border-background-positive-text;
}
.lower-bound {
  @apply mb-11 text-text-negative text-xl20 font-bold border-0 border-dotted border-b-4 border-background-negative-text;
}
.chart-loading {
  height: 375px;
}
</style>
