<template>
  <TradeCard :isLoading="isSkeletonLoading" :skeletonLoading="skeletonOptions">
    <div class="flex justify-between" slot="header">
      <h3 class="text-n-4xl font-bold lg:text-n-3xl">
        {{ $t('trade.vaulted_ids') }}
      </h3>
      <div 
        v-if="isUserAuthenticated"
        class="lg:hidden block">
        <ButtonV2 
          size="medium"
          version="secondary"
          testId="btn-sell_card"
          :label="$tc('stats_card.sell_card_button')"
          @onClick="openSellByDibbs"
        />
      </div>
    </div>

    <div class="serial-ids" role="serials">
      <div class="text-text-inactive lg:body-text-x-medium body-text-x-large mb-s4 xl:mt-s2">{{ content.title }}</div>
      <div class="lg:subheadline-medium subheadline-large">{{ content.body }}</div>
    </div>

    <div 
      v-if="isUserAuthenticated"
      class="lg:block hidden mt-n-3xl">
      <ButtonV2 
        size="medium"
        version="secondary"
        :label="$tc('stats_card.sell_card_button')"
        testId="btn-sell_card"
        @onClick="openSellByDibbs"
      />
    </div>

  </TradeCard>
</template>

<script>
import ButtonV2 from '@/stories/misc/ButtonV2';
import TradeCard from '@/components/trade/TradeCard';

export default {
  name: 'Stats',
  components: {
    ButtonV2,
    TradeCard
  },
  data:() =>({
    skeletonOptions: { 
      height: '191px',
    }
  }),
  props: {
    address: Object,
    cardName: String,
    email: String,
    marketCap: Number,
    supply: String,
    userName: String,
    individualAssets: Array,
    isUserAuthenticated: {
      type: Boolean,
      default: () => false,
    },
    isSkeletonLoading:  {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    grade(){
      return this.cardName.match(/((PSA|BGS).+)/g)[0];
    },
    content(){
      const assets = this.individualAssets ? [...this.individualAssets] : [];
      const serials = assets.map(asset => asset.serial).join(', ');
      return {
        id: 0,
        title: this.$tc('trade.serial_numbers', assets.length),
        body: serials,
      };
    }
  },
  methods: {
    openSellByDibbs(){
      const { city, postalCode, state, street } = this.address;
      const url = new URL('https://7hkv6xgop3d.typeform.com/to/xAflDYNM');
      url.searchParams.append('email', this.email);
      url.searchParams.append('card_name', this.cardName);
      url.searchParams.append('grade', this.grade);
      url.searchParams.append('address', street);
      url.searchParams.append('city', city);
      url.searchParams.append('state', state);
      url.searchParams.append('zipCode', postalCode);
      url.searchParams.append('fullName', this.userName);
      this.goToExternalPageGlobal(url.toString());
      this.$store.dispatch('events/track', {
        event: 'SELLERS_FORM_VIEWED',
      });
    }
  }
};
</script>

<style scoped>
  .serial-ids {
    @apply flex flex-col font-semibold;
  }
</style>
