<template>
  <TradeCard 
    v-if="displayStatus.isVisible"
    :isLoading="isSkeletonLoading">
    <div class="flex justify-between" slot="header">
      <div class="text-n-4xl font-bold lg:text-n-3xl">
        {{ $t('navigation.active_orders') }}
      </div>
      <div 
        v-if="displayStatus.isOffLimit"
        class="lg:hidden block">
        <ButtonV2 
          size="medium"
          version="secondary"
          :label="$t('active_orders.button')"
          testId="btn-active_orders"
          @onClick="$router.push('/orders/active')"
        />
      </div>
    </div>
     <ul class="lg:text-n-md text-n-lg font-bold">
       <li class="active-orders-grid gap-n-md grid text-text-inactive lg:body-text-x-medium body-text-x-large">
         <p>{{$t('active_orders.header.type')}}</p>
         <p>{{$t('active_orders.header.amount')}}</p>
         <p>{{$t('active_orders.header.card_price')}}</p>
         <p>{{$t('active_orders.header.filled')}}</p>
       </li> 
       <li :key="id" class="active-orders-grid gap-n-md grid last:border-b-0 border-b border-border py-n-lg leading-none" v-for="(order, id) in ordersFormatted">
         <div class="flex flex-col justify-center">
           <h4 :class="['lg:text-n-lg text-n-2xl', order.typeColor]">
             {{order.type}}
           </h4> 
         </div>
         <div class="flex flex-col justify-center">
           <p class="truncate">
             ${{order.amount}}
           </p> 
         </div>
         <div class="flex flex-col justify-center">
           <p class="truncate">
             ${{order.price}}
           </p> 
         </div>
         <div class="flex flex-col justify-center">
           <p>
             {{order.filled}}%
           </p> 
         </div>
        <div class="flex flex-col justify-center items-center">
          <button 
            :aria-label="$t('active_orders.close_button')" 
            :class="[ 'active-orders-button', isLoading ? 'active-orders-button:disabled': '' ]" @click="cancelOrder(order.order_id, id)" 
            :disabled="isLoading">
            <IconCloseCircle class="active-orders-close-icon" />
          </button>
        </div>
       </li>
     </ul>

     <div 
       v-if="displayStatus.isOffLimit"
       class="lg:block hidden mt-n-3xl">
      <ButtonV2 
        size="medium"
        version="secondary"
        :label="$t('active_orders.button')" 
        testId="btn-active_orders-off-limit"
        @onClick="$router.push('/orders/active')"
      />
    </div>
  </TradeCard>
</template>
<script>

import { IconCloseCircle } from '@/assets/icons';
import ButtonV2 from '@/stories/misc/ButtonV2.vue';
import TradeCard from '@/components/trade/TradeCard';

export default {
  name: 'ActiveOrders',
  components: {
    ButtonV2,
    TradeCard,
    IconCloseCircle
  },
  props: {
    orders: {
      type: Array,
      required: true
    },
    ordersLimit: {
      type: Number,
      default: 10
    },
    isLoading: {
      type: Boolean,
      default: () => false
    },
    isSkeletonLoading: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    displayStatus(){
      return {
        isVisible: !!this.orders.length,
        isOffLimit: this.orders.length > this.ordersLimit
      };
    },
    ordersFormatted(){
      if (this.isSkeletonLoading) {
        return [];
      }

      const filterByLimit = this.displayStatus.isOffLimit ? [...this.orders].splice(0, this.ordersLimit) : this.orders;
      
      return filterByLimit.map(order => {
        const { amount, price, remaining } = order;

        return Object.assign({...order}, {
          typeColor: order.type === 'BUY' ? 'text-text-positive' : 'text-text-negative',
          amount: this.numberFormat(amount),
          price: this.numberFormat(price),
          filled: this.numberFormat(this.$big(amount).minus(remaining).div(amount).times(100)),
        });
      });
    }
  },
  methods: {
    cancelOrder(orderId, orderIndex){
      return this.$emit('cancelOrder', {orderId, orderIndex});
    },
  }
};
</script>
<style scoped>
.active-orders-grid{
  grid-template-columns: 1fr repeat(2, minmax(0, 1.5fr)) 1fr 50px;
}
.active-orders-button{
  outline: 0;
  stroke: var(--colors-text-body);
}
.active-orders-button:disabled{
  stroke: rgba(var(--colors-text-inactive), 1);
}
.active-orders-close-icon {
  height: 24px;
  width: 24px;
}
</style>
